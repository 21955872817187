import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  ListItemIcon,
  Menu,
  MenuItem,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import HeadingTitle from "../../Component/s/HeadingTitle";
import SearchTableForm from "../../Component/Form/SearchTableForm";
import doctorManageData from "../../Data/doctorManageData";
import { Helmet } from "react-helmet-async";
import VisibilityIcon from "@mui/icons-material/Visibility";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function ManageDoctor() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [managePatientData, setManagePatientData] = useState(doctorManageData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - managePatientData.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Get Search Value
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(searchValue, "patient Mange search value");
  };

  return (
    <>
      <Helmet>
        <title>Manage Doctor</title>
        <meta name="description" content="Web description" />
        <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <div className="manage_view_wrapper">
        <HeadingTitle text={"Manage Doctor"} />
        <SearchTableForm
          searchValue={searchValue}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Doctor ID
                </TableCell>
                <TableCell sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Doctor Name{" "}
                </TableCell>
                <TableCell sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Doctor Email
                </TableCell>
                <TableCell sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Doctor Bio
                </TableCell>
                <TableCell sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? managePatientData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : managePatientData
              ).map((item) => (
                <TableRow key={item.id}>
                  <TableCell>Dr-{item.doctorID} </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <img
                        src={item.doctorImage}
                        alt="doctor img"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                      <Typography variant="h6" sx={{ fontSize: "16px" }}>
                        {item.doctorName}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>{item.doctorEmail}</TableCell>
                  <TableCell>{(item?.doctorBio).slice(0, 50)}...</TableCell>
                  <TableCell>
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Tooltip title="Action">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            boxShadow: "2px 2px 5px #c0bdbd33",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem>
                          <Link
                            to={"/view-doctor"}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              width: "100%",
                            }}
                          >
                            <ListItemIcon>
                              <VisibilityIcon fontSize="small" />
                            </ListItemIcon>
                            View
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link
                            to={"/edit-doctor"}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              width: "100%",
                            }}
                          >
                            <ListItemIcon>
                              <ModeEditIcon fontSize="small" />
                            </ListItemIcon>
                            Edit
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <button
                            type="button"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              padding: "0",
                              width: "100%",
                            }}
                          >
                            <ListItemIcon>
                              <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            Delete
                          </button>
                        </MenuItem>
                      </Menu>
                    </>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={managePatientData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default ManageDoctor;
