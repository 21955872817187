import React from 'react'

function HeadingTitle({text,extraClass,children}) {
  return (
    <div className={`header_area ${extraClass?extraClass :""}`}>
      <h3 className='page_title'>{text} </h3>
        {children && children }
    </div>
  )
}




export default HeadingTitle