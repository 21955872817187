import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';

function PatientModal({modalTittle ,modalTopText , modalBody,  handleOpen,handleClose}) {
  return (
    <Dialog open={handleOpen} onClose={handleClose}>
        <DialogTitle>{modalTittle} </DialogTitle>
        <DialogContent>
          <DialogContentText>
         {modalTopText && modalTopText }
          </DialogContentText>
       {modalBody && modalBody}
        </DialogContent>
     
      </Dialog>
  )
}




export default PatientModal