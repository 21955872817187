import * as React from "react";
import { useContext } from "react";
import { IconButton } from "@mui/material";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./header.css";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useState } from "react";
import SidebarContext from "../Context/SidebarContext";
function Header() {
  const [stickyHeader, setstickyHeader] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Sidebar Handle Events
  const { handleToggleSidebar } = useContext(SidebarContext);
  const stickyNavbar = () => {
    if (window.scrollY >= 50) {
      setstickyHeader(true);
    } else {
      setstickyHeader(false);
    }
  };
  window.addEventListener("scroll", stickyNavbar);
  const navigate = useNavigate();
  //Log out
  const handleLogout = () => {
    localStorage.setItem("user", "");
    navigate("/login");
  };

  return (
    <header className={`header_wrapper ${stickyHeader ? "header_fixed" : ""}`}>
      <div className="logo_area">
        <Link to={"/dashboard"}>
          <img src="/images/logo.jpg" alt="logo" />
        </Link>
      </div>
      <div className="menu_toggle_btn">
        <IconButton aria-label="delete" onClick={handleToggleSidebar}>
          <HiOutlineMenuAlt1 />
        </IconButton>
      </div>
      <div className="profile_user_area">
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              textAlign: "center",
            }}
          >
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar>
                <img src="/images/user_img.jpg" alt="" className="user_img" />
              </Avatar>
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => navigate("/profile")}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem onClick={() => navigate("/my-account")}>
              <Avatar /> My account
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>
      </div>
    </header>
  );
}

export default Header;
