import {
  Avatar,
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { PureComponent } from "react";
import HeadingTitle from "../../Component/s/HeadingTitle";
import { FaUserFriends, FaUserNurse, FaUserTie } from "react-icons/fa";
import { BsHourglass } from "react-icons/bs";
import { cyan, green, grey, lightBlue, pink } from "@mui/material/colors";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Select from "react-select";
import { useState } from "react";
import { Stack } from "@mui/system";
import { Helmet } from "react-helmet-async";
//Chart Data
const dataGender = [
  { name: "Male", value: 400 },
  { name: "Famale", value: 300 },
];

const COLORS = ["#0088FE", "#00C49F"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

//Bar Chart Data
const selectYearData = [
  {
    id: 1,
    label: "Last 1 Year",
    value: "last_year",
  },
  {
    id: 2,
    label: "Last 2 Year",
    value: "last_2year",
  },
  {
    id: 1,
    label: "Last 3 Year",
    value: "last_3year",
  },
];

const dataGenderGroup = [
  {
    name: "Fri",
    Male: 4000,
    Famale: 2400,
    amt: 2400,
  },
  {
    name: "Sat",
    Male: 3000,
    Famale: 1398,
    amt: 2210,
  },
  {
    name: "Sun",
    Male: 2000,
    Famale: 9800,
    amt: 2290,
  },
  {
    name: "Mon",
    Male: 2780,
    Famale: 3908,
    amt: 2000,
  },
  {
    name: "Tue",
    Male: 1890,
    Famale: 4800,
    amt: 2181,
  },
  {
    name: "Wed",
    Male: 2390,
    Famale: 3800,
    amt: 2500,
  },
  {
    name: "Thu",
    Male: 3490,
    Famale: 4300,
    amt: 2100,
  },
];

function Dashboard() {
  const [cardHovers, setCardHovers] = useState({
    card1: 0,
    card2: 0,
    card3: 0,
    card4: 0,
  });
  const handleYearChange = (e) => {
    console.log(e, "year select value");
  };

  return (
    <>
    <Helmet>
       <title>Dashboard</title>
       <meta name="description" content="Web description" />
       <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
     </Helmet>
    <div className="dashboar_page_wrapper">
      <HeadingTitle text={"Dashboard"} />
      <div className="overview_wrapper">
        <Grid container spacing={2}>
          <Grid item lg={3} sm={6} xs={12}>
            <Paper
              elevation={cardHovers.card1}
              sx={{ p: 3, cursor: "pointer" }}
              onMouseOver={() =>
                setCardHovers({ card1: 3, card2: 0, card3: 0, card4: 0 })
              }
              onMouseOut={() =>
                setCardHovers({ card1: 0, card2: 0, card3: 0, card4: 0 })
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Avatar
                  variant="rounded"
                  sx={{ width: 72, height: 72, background: cyan[600], p: 1.4 }}
                >
                  <FaUserTie size={45} />
                </Avatar>
                <Stack>
                  <Typography
                    variant="h4"
                    color={"0C1E5B"}
                    sx={{ textAlign: "right" }}
                  >
                    400
                  </Typography>
                  <Typography variant="h6" color={grey[500]}>
                    Patients
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Paper
              elevation={cardHovers.card2}
              sx={{ p: 3, cursor: "pointer" }}
              onMouseOver={() =>
                setCardHovers({ card1: 0, card2: 3, card3: 0, card4: 0 })
              }
              onMouseOut={() =>
                setCardHovers({ card1: 0, card2: 0, card3: 0, card4: 0 })
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Avatar
                  variant="rounded"
                  sx={{ width: 72, height: 72, background: green[600], p: 1.4 }}
                >
                  <FaUserNurse size={45} />
                </Avatar>
                <Stack>
                  <Typography
                    variant="h4"
                    color={"0C1E5B"}
                    sx={{ textAlign: "right" }}
                  >
                    60
                  </Typography>
                  <Typography variant="h6" color={grey[500]}>
                    Doctor
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Paper
              elevation={cardHovers.card3}
              sx={{ p: 3, cursor: "pointer" }}
              onMouseOver={() =>
                setCardHovers({ card1: 0, card2: 0, card3: 3, card4: 0 })
              }
              onMouseOut={() =>
                setCardHovers({ card1: 0, card2: 0, card3: 0, card4: 0 })
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    width: 72,
                    height: 72,
                    background: lightBlue[600],
                    p: 1.4,
                  }}
                >
                  <FaUserFriends size={45} />
                </Avatar>
                <Stack>
                  <Typography
                    variant="h4"
                    color={"0C1E5B"}
                    sx={{ textAlign: "right" }}
                  >
                    6500
                  </Typography>
                  <Typography variant="h6" color={grey[500]}>
                    Staff
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Paper
              elevation={cardHovers.card4}
              sx={{ p: 3, cursor: "pointer" }}
              onMouseOver={() =>
                setCardHovers({ card1: 0, card2: 0, card3: 0, card4: 3 })
              }
              onMouseOut={() =>
                setCardHovers({ card1: 0, card2: 0, card3: 0, card4: 0 })
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Avatar
                  variant="rounded"
                  sx={{ width: 72, height: 72, background: pink[600], p: 1.4 }}
                >
                  <BsHourglass size={45} />
                </Avatar>
                <Stack>
                  <Typography
                    variant="h4"
                    color={"0C1E5B"}
                    sx={{ textAlign: "right" }}
                  >
                    60
                  </Typography>
                  <Typography variant="h6" color={grey[500]}>
                    Assess done
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>

          {/* <Grid item lg={3} md={4} sm={6} xs={12} key={item.id}>
                <List className="overview_item">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          width: "60px",
                          height: "60px",
                          marginRight: "12px",
                          borderRadius:"0px",
                        }}
                      >
                        {item.icon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.text} secondary={item.number} />
                  </ListItem>
                </List>
              </Grid> */}
        </Grid>
      </div>
      <Box className="dashboard_chart_wrapper">
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Paper className="chart_item_wrapper">
              <div className="chart_header_area">
                <Typography variant="h3">Gender Patient:</Typography>
              </div>
              <div className="chart_body_area" id="genderArea">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={dataGender}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {dataGender.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <ul className="pie_label_list">
                  <li className="famale_lable">Famale</li>
                  <li className="male_lable">Male</li>
                </ul>
              </div>
            </Paper>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Paper className="chart_item_wrapper">
              <div className="chart_header_area">
                <Typography variant="h3">Gender Patient Group:</Typography>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={selectYearData[0]}
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  placeholder="Select Year"
                  options={selectYearData.map((item) => {
                    return {
                      value: item.value,
                      label: item.label,
                    };
                  })}
                  onChange={(e) => handleYearChange(e)}
                />
              </div>
              <div className="chart_body_area" id="genderArea">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={dataGenderGroup}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Male" fill="#8884d8" />
                    <Bar dataKey="Famale" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
    </>
  );
}

export default Dashboard;
