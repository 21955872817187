import "./sidebar.css";
import * as React from "react";
import { useState, useContext } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import DraftsIcon from "@mui/icons-material/Drafts";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { NavLink } from "react-router-dom";
import { Paper } from "@mui/material";
import SidebarContext from "../../Context/SidebarContext";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
function Sidebar() {
  const [open, setOpen] = useState(false);
  const { toggleSidebar,handleToggleSidebar } = useContext(
    SidebarContext
  );
  const handleClick = () => {
    setOpen(!open);
  };

  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Paper elevation={3} >
      <aside className="sidebar_wrapper">
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          className="menu_list_wrapper"
        >
          <div className="accordion_wrapper">
            <NavLink to={"/"}>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </NavLink>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DraftsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Patient Assessment" />
                </ListItemButton>
              </AccordionSummary>
              <AccordionDetails>
                <NavLink to={"/add-patient"}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <PersonAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add Patient" />
                    </ListItemButton>
                  </List>
                </NavLink>
                <NavLink to={"/manage-patient"}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <ManageAccountsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Patient" />
                    </ListItemButton>
                  </List>
                </NavLink>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel12"}
              onChange={handleChange("panel12")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <LocalHospitalIcon />
                  </ListItemIcon>
                  <ListItemText primary="Doctor" />
                </ListItemButton>
              </AccordionSummary>
              <AccordionDetails>
                <NavLink to={"/add-doctor"}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <PersonAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add Doctor" />
                    </ListItemButton>
                  </List>
                </NavLink>
                <NavLink to={"/manage-doctor"}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <ManageAccountsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Doctor" />
                    </ListItemButton>
                  </List>
                </NavLink>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel13"}
              onChange={handleChange("panel13")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
              </AccordionSummary>
              <AccordionDetails>
                <NavLink to={"/manage-staff"}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <ManageAccountsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Staff" />
                    </ListItemButton>
                  </List>
                </NavLink>
                <NavLink to={"/manage-admin"}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <AdminPanelSettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Admin" />
                    </ListItemButton>
                  </List>
                </NavLink>
                <NavLink to={"/comming-soon"}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <ManageHistoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="General Settings" />
                    </ListItemButton>
                  </List>
                </NavLink>
              </AccordionDetails>
            </Accordion>
          </div>
        </List>
      </aside>
      {window.innerWidth<992 && (<div className="overlay" style={{display:`${toggleSidebar?"block":"none"}` } } onClick={handleToggleSidebar} ></div> ) }
    </Paper>
  );
}

export default Sidebar;
