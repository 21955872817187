import { Avatar, Box, Divider, Paper } from "@mui/material";
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { AiOutlineUser } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { TbPhoneCall } from "react-icons/tb";
import { Helmet } from "react-helmet-async";
import FactCheckIcon from "@mui/icons-material/FactCheck";
function ViewDoctor() {
  return (
    <>
      <Helmet>
        <title>View Doctor</title>
        <meta name="description" content="Web description" />
        <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <Box className="profile_wrapper">
        <Paper
          sx={{
            maxWidth: "500px",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "20px",
          }}
        >
          <Avatar
            variant="square"
            sx={{
              width: "100px",
              height: "100px",
              margin: "auto",
              objectFit: "cover",
            }}
          >
            <img
              src="/images/user_img.jpg"
              alt=""
              style={{ objectFit: "cover" }}
            />
          </Avatar>
          <Divider sx={{ margin: "20px 0" }} />
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItem sx={{ alignItems: "start" }}>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#1565c0" }}>
                  <AiOutlineUser color="white" size={18} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Doctor Name" secondary="Johan" />
            </ListItem>
            <ListItem sx={{ alignItems: "start" }}>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#1565c0" }}>
                  <MdEmail color="white" size={18} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Doctor Email"
                secondary="Example@gmail.com"
              />
            </ListItem>
            <ListItem sx={{ alignItems: "start" }}>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#1565c0" }}>
                  <TbPhoneCall color="white" size={18} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Doctor Bio"
                secondary="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus eaque perferendis cumque placeat illo rerum impedit facilis deleniti nulla repellat."
              />
            </ListItem>

            <ListItem sx={{ alignItems: "start" }}>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#1565c0" }}>
                  <FactCheckIcon color="white" size={18} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Status" secondary="Active" />
            </ListItem>
          </List>
        </Paper>
      </Box>
    </>
  );
}

export default ViewDoctor;
