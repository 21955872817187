import * as React from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, IconButton } from "@mui/material";
function SearchTableForm({ searchValue, handleChange, handleSubmit }) {
  return (
    <div className="search_tabl_form_area">
      <form action="" onSubmit={handleSubmit}>
        <TextField
          id="outlined-name"
          label="Search"
          value={searchValue}
          onChange={handleChange}
          size="small"
          fullWidth
        />

        <IconButton aria-label="search" className="serach_icon" type="submit">
          <SearchIcon />
        </IconButton>
      </form>
    </div>
  );
}

export default SearchTableForm;
