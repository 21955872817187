import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout/Layout";
import Dashboard from "./Pages/Dashboard/Dashboard";
import AddDoctor from "./Pages/Doctor/AddDoctor";
import EditDoctor from "./Pages/Doctor/EditDoctor";
import ManageDoctor from "./Pages/Doctor/ManageDoctor";
import AddPatientPage from "./Pages/Patient/AddPatientPage";
import ManagePatient from "./Pages/Patient/ManagePatient";
import PatientViewPage from "./Pages/Patient/PatientViewPage";
import Login from "./Pages/Profile/Login";
import ManageStaff from "./Pages/Staff/ManageStaff";
import CommingSoon from "./Pages/CommingSoon";
import EditStaff from "./Pages/Staff/EditStaff";
import AddStaff from "./Pages/Staff/AddStaff";
import ProtectRoutes from "./Router/ProtectRoutes";
import ForgetPassword from "./Pages/Profile/ForgetPassword";
import ChangePassword from "./Pages/Profile/ChangePassword";
import AssessmentPage from "./Pages/Patient/AssessmentPage";
import EditPatientPage from "./Pages/Patient/EditPatientPage";
import Profile from "./Pages/Account/Profile";
import { HelmetProvider } from "react-helmet-async";
import AccountSetting from "./Pages/Account/AccountSetting";
import NotFound from "./Pages/NotFound";
import AddAdmin from "./Pages/Admin/AddAdmin";
import EditAdmin from "./Pages/Admin/EditAdmin";
import ManageAdmin from "./Pages/Admin/ManageAdmin";
import ViewDoctor from "./Pages/Doctor/ViewDoctor";
import ViewStaff from "./Pages/Staff/ViewStaff";
function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="forget-password" element={<ForgetPassword />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="/" element={<ProtectRoutes />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="add-patient" element={<AddPatientPage />} />
              <Route path="edit-patient" element={<EditPatientPage />} />
              <Route path="manage-patient" element={<ManagePatient />} />
              <Route path="patient-view" element={<PatientViewPage />} />
              <Route path="patient-assessment" element={<AssessmentPage />} />
              <Route path="add-doctor" element={<AddDoctor />} />
              <Route path="manage-doctor" element={<ManageDoctor />} />
              <Route path="view-doctor" element={<ViewDoctor />} />
              <Route path="edit-doctor" element={<EditDoctor />} />
              <Route path="manage-staff" element={<ManageStaff />} />
              <Route path="add-staff" element={<AddStaff />} />
              <Route path="view-staff" element={<ViewStaff />} />
              <Route path="edit-staff" element={<EditStaff />} />
              <Route path="profile" element={<Profile />} />
              <Route path="my-account" element={<AccountSetting />} />
              <Route path="edit-staff" element={<EditStaff />} />
              <Route path="add-admin" element={<AddAdmin />} />
              <Route path="edit-admin" element={<EditAdmin />} />
              <Route path="manage-admin" element={<ManageAdmin />} />
              <Route path="*" element={<NotFound />} />
              <Route path="comming-soon" element={<CommingSoon />} />
            </Route>
          </Route>
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </HelmetProvider>
  );
}

export default App;
