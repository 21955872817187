import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import PatientViewPage from "./PatientViewPage";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import ReactSelect from "react-select";
import { Helmet } from "react-helmet-async";
const validationSchema = Yup.object().shape({
  selectDoctor: Yup.string().required("Doctor  is required"),
  comment: Yup.string().required("Comment  is required"),
});
function AssessmentPage() {
  const [comment, setComment] = useState("");

  //react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    console.log("Submit comment", data);
  };
  return (
    <>
    <Helmet>
       <title>Assesment Patient</title>
       <meta name="description" content="Web description" />
       <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
     </Helmet>
    <PatientViewPage
      isprintBtn={false}
      afterViewContent={
        <Box sx={{ mt: 4 }}>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      render={({ field, formState }) => (
                        <FormControl sx={{ mt: 2 }} fullWidth>
                          <Typography
                            color={
                              !!formState.errors?.selectDoctor ? red[700] : ""
                            }
                          >
                            Select Doctor*
                          </Typography>

                          <ReactSelect
                            styles={{
                              control: (base) => ({
                                ...base,
                                border: !!formState.errors?.selectDoctor
                                  ? "1px solid #D32F2F"
                                  : "1px solid #C4C4C4",
                                "&:hover": {
                                  border: !!formState.errors?.selectDoctor
                                    ? "1px solid #D32F2F"
                                    : "1px solid #C4C4C4",
                                },
                              }),
                            }}
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={[
                              {
                                id: 1,
                                label: "Johan",
                                value: "johan",
                              },
                              {
                                id: 2,
                                label: "Hernando",
                                value: "hernando",
                              },
                              {
                                id: 3,
                                label: "Rebe",
                                value: "rebe",
                              },
                              {
                                id: 4,
                                label: "Catterall",
                                value: "catterall",
                              },
                            ]}
                            // value={
                            //   field?.value !== ""
                            //     ? countryList?.filter(
                            //         (country) => country?.id === field?.value
                            //       )[0]
                            //     : null
                            // }
                            onChange={(selectedOption) =>
                              field.onChange(selectedOption?.value)
                            }
                          />

                          {!!formState.errors?.selectDoctor ? (
                            <FormHelperText error>
                              {errors?.selectDoctor?.message}
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </FormControl>
                      )}
                      name="selectDoctor"
                      control={control}
                      defaultValue=""
                    />
                  </Grid>
                </Grid>
                <Controller
                  render={({ field, formState }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ mt: 2 }}
                      size="small"
                    >
                      <Typography
                        color={!!formState.errors?.comment ? red[700] : ""}
                      >
                        Commnet
                      </Typography>

                      <OutlinedInput
                        {...field}
                        error={!!formState.errors?.comment}
                        placeholder="Enter Your Message"
                        multiline
                      />
                      {!!formState.errors?.comment ? (
                        <FormHelperText error>
                          {errors?.comment?.message}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  name="comment"
                  control={control}
                  defaultValue=""
                />

                <Button variant="contained" type="submit" sx={{ mt: 3 }}>
                  Submit{" "}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      }
    />
    </>
  );
}

export default AssessmentPage;
