import { useState, createContext } from "react";

const SidebarContext = createContext();

export const SidebarContextProvider = ({ children }) => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mobileSidebar, setMobileSidebar] = useState(false);

  //Toggle Sidebar
  const handleToggleSidebar = () => {
    if (window.innerWidth < 992) {
      if (toggleSidebar === true) {
        document.querySelector("html").style.cssText = "overflow: auto";
      } else {
        document.querySelector("html").style.cssText = "overflow: hidden";
      }
    }
    setToggleSidebar(!toggleSidebar);
  };
  return (
    <SidebarContext.Provider value={{ toggleSidebar, handleToggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
