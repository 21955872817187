import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import * as Yup from "yup";
import { toast } from "react-toastify";
import HeadingTitle from "../../Component/s/HeadingTitle";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
const validationSchema = Yup.object().shape({
  doctorName: Yup.string().required("Staff name is required"),
  doctorEmail: Yup.string().email().required("Staff email is required"),
  doctorNewPassword: Yup.string().required("Staff new password is required"),
  doctorConfrimPassword: Yup.string().required(
    "Staff confirm password is required"
  ),
  //   doctorStatus: Yup.bool().required("Doctor Status is required"),
});

function EditStaff() {
  const [showPassword, setShowPassword] = useState({

    newPassword: false,
    confirmPassword: false,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    console.log(data, "Add Staff Data");
    toast.success("Successfully Updated");
  };
  return (
    <>
    <Helmet>
       <title>Edit Staff</title>
       <meta name="description" content="Web description" />
       <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
     </Helmet>
    <section className="add_doctor_wrapper">
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            sx={{ my: 2 }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={6}>
              <HeadingTitle text={"Edit Staff"} />
              <Paper elevation={2} sx={{ p: 2 }}>
                <Controller
                  render={({ field, formState }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      sx={{ mt: 2 }}
                    >
                      <Typography
                        color={!!formState.errors?.doctorName ? red[700] : ""}
                      >
                        Staff Name
                      </Typography>
                      <OutlinedInput
                        {...field}
                        error={!!formState.errors?.doctorName}
                        placeholder="Enter Doctor Name"
                      />
                      {!!formState.errors?.doctorName ? (
                        <FormHelperText error>
                          {errors?.doctorName?.message}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  name="doctorName"
                  control={control}
                  defaultValue="johan"
                />
                <Controller
                  render={({ field, formState }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      sx={{ mt: 2 }}
                    >
                      <Typography
                        color={!!formState.errors?.doctorEmail ? red[700] : ""}
                      >
                        Staff Email
                      </Typography>
                      <OutlinedInput
                        {...field}
                        error={!!formState.errors?.doctorEmail}
                        placeholder="Enter Doctor Name"
                      />
                      {!!formState.errors?.doctorEmail ? (
                        <FormHelperText error>
                          {errors?.doctorEmail?.message}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  name="doctorEmail"
                  control={control}
                  defaultValue="doctor@gmail.com"
                />

                <Controller
                  render={({ field, formState }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      sx={{ mt: 2 }}
                    >
                      <Typography
                        color={
                          !!formState.errors?.doctorNewPassword ? red[700] : ""
                        }
                      >
                        New Password
                      </Typography>
                      <OutlinedInput
                        {...field}
                        error={!!formState.errors?.doctorNewPassword}
                        placeholder="Enter New Password"
                        type={showPassword?.newPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword({
                                  ...showPassword,
                                  newPassword: !showPassword?.newPassword,
                                });
                              }}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword?.newPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {!!formState.errors?.doctorNewPassword ? (
                        <FormHelperText error>
                          {errors?.doctorNewPassword?.message}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  name="doctorNewPassword"
                  control={control}
                  defaultValue=""
                />
                <Controller
                  render={({ field, formState }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      sx={{ mt: 2 }}
                    >
                      <Typography
                        color={
                          !!formState.errors?.doctorConfrimPassword
                            ? red[700]
                            : ""
                        }
                      >
                        Confirm Password
                      </Typography>
                      <OutlinedInput
                        {...field}
                        error={!!formState.errors?.doctorConfrimPassword}
                        placeholder="Enter Confirm Password"
                        type={
                          showPassword?.confirmPassword ? "text" : "password"
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword({
                                  ...showPassword,
                                  confirmPassword:
                                    !showPassword?.confirmPassword,
                                });
                              }}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword?.confirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {!!formState.errors?.doctorConfrimPassword ? (
                        <FormHelperText error>
                          {errors?.doctorConfrimPassword?.message}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  name="doctorConfrimPassword"
                  control={control}
                  defaultValue=""
                />
                {/* <Controller
                render={({ field, formState }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ mt: 2 }}

                  >
                       <FormGroup>
        <FormControlLabel
          control={
            <Switch name="gilad" />
          }

          label="Doctor Active"
        />


      </FormGroup>
                    {!!formState.errors?.doctorStatus ? (
                      <FormHelperText error>
                        {errors?.doctorStatus?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                )}
                name="doctorStatus"
                control={control}
                defaultValue={true}
              /> */}
                <FormControl
                  component="fieldset"
                  variant="standard"
                  sx={{ mt: 2 }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch name="gilad" />}
                      label="Staff Active"
                    />
                  </FormGroup>
                </FormControl>
                <Grid container spacing={2} sx={{ my: 2 }}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        reset({
                          doctorName: "",
                          doctorEmail: "",
                          doctorPassword: "",
                        })
                      }
                      color="inherit"
                      fullWidth
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="success"
                      fullWidth
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </section>
    </>
  );
}

export default EditStaff;
