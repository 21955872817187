import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
const validationSchema = Yup.object().shape({
  oldPassowrd: Yup.string().required("Enter your old passowrd"),
  newPassword: Yup.string().required("new password is required"),
  confirmPassword: Yup.string().required("Confirm password is required"),
});

function ChangePassword() {
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    console.log("Change Passowrd", data);
    toast.success("Successfully Change Password");
    reset();
  };
  return (
    <>
      <Helmet>
        <title>Change Passowrd</title>
        <meta name="description" content="Web description" />
        <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <Box>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            render={({ field, formState }) => (
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}
              >
                <Typography
                  color={!!formState.errors?.oldPassowrd ? red[700] : ""}
                >
                  Old Password
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.oldPassowrd}
                  placeholder="Enter Old Password"
                  type={showPassword?.oldPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword({
                            ...showPassword,
                            oldPassword: !showPassword?.oldPassword,
                          });
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword?.oldPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!formState.errors?.oldPassowrd ? (
                  <FormHelperText error>
                    {errors?.oldPassowrd?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="oldPassowrd"
            control={control}
            defaultValue=""
          />
          <Controller
            render={({ field, formState }) => (
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}
              >
                <Typography
                  color={!!formState.errors?.newPassword ? red[700] : ""}
                >
                  New Password
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.newPassword}
                  placeholder="Enter New Password"
                  type={showPassword?.newPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword({
                            ...showPassword,
                            newPassword: !showPassword?.newPassword,
                          });
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword?.newPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!formState.errors?.newPassword ? (
                  <FormHelperText error>
                    {errors?.newPassword?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="newPassword"
            control={control}
            defaultValue=""
          />
          <Controller
            render={({ field, formState }) => (
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}
              >
                <Typography
                  color={!!formState.errors?.confirmPassword ? red[700] : ""}
                >
                  Confirm Password
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.confirmPassword}
                  placeholder="Enter Confirm Password"
                  type={showPassword?.confirmPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword({
                            ...showPassword,
                            confirmPassword: !showPassword?.confirmPassword,
                          });
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword?.confirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!formState.errors?.confirmPassword ? (
                  <FormHelperText error>
                    {errors?.confirmPassword?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="confirmPassword"
            control={control}
            defaultValue=""
          />

          <Grid container spacing={1} sx={{ marginTop: "40px" }}>
            <Grid item xs={6}>
              <Button variant="contained" type="submit" sx={{ width: "100%" }}>
                Change Password
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                color="secondary"
                onClick={() => window.history.go(-1)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default ChangePassword;
