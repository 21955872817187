import {
  Button,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  TableHead,
  Paper,
  Divider,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import HeadingTitle from "../../Component/s/HeadingTitle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
function PatientViewPage({ isprintBtn, afterViewContent }) {
  const handlePrint = () => {
    document.body.classList.add("print_view");
    window.print();
    document.body.classList.remove("print_view");
  };

  return (
    <>
      <Helmet>
        <title>Patient View </title>
        <meta name="description" content="Web description" />
        <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <section className="patient_view_wrapper">
        {isprintBtn && (
          <Box className="print_btn" sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handlePrint}>
              Print{" "}
            </Button>
          </Box>
        )}
        <HeadingTitle text={"HF Pattient Assessment"} />
        <Box>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Patient Information
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">Patient ID:&nbsp;54615</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        Patient Name:&nbsp;Johan
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        Enrollment Date:&nbsp;13/12/2022
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Contact Information
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        Address:&nbsp;Dhanmondi,Dhaka,Bangladesh
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        Care Giver Name:&nbsp;06512157
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        Address:&nbsp;Dhanmondi,Dhaka,Bangladesh
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">Relation:&nbsp;Islam</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        Patient Mobile Number:&nbsp;6489121
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        Care Give Mobile Number:&nbsp;6489121
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 3 }} />

          <Grid container spacing={2}>
            <Grid item sm={6} xs={6}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderBottom: "none",
                  }}
                  style={{ marginBottom: "0px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Demography
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <Table
                  className="middle_divied_table"
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderTop: "none",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Age
                      </TableCell>
                      <TableCell width="50%" size="small">
                        21
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Waist
                      </TableCell>
                      <TableCell width="50%" size="small">
                        21
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Height
                      </TableCell>
                      <TableCell width="50%" size="small">
                        21
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Weight
                      </TableCell>
                      <TableCell width="50%" size="small">
                        21
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        BMI
                      </TableCell>
                      <TableCell width="50%" size="small">
                        21
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Sex
                      </TableCell>
                      <TableCell width="50%" size="small">
                        Male
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Blood Group
                      </TableCell>
                      <TableCell width="50%" size="small">
                        A
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item sm={6} xs={6}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderBottom: "none",
                  }}
                  style={{ marginBottom: "0px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Socio Economical Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <Table
                  className="middle_divied_table"
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderTop: "none",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Education Status:
                      </TableCell>
                      <TableCell width="50%" size="small">
                        Literate(Gradutate){" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Type of Occupation:
                      </TableCell>
                      <TableCell width="50%" size="small">
                        Worker
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Maonth Income
                      </TableCell>
                      <TableCell width="50%" size="small">
                        30000BDT+
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />
          <HeadingTitle text={"Complaints"} />
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Symptoms
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Breathlessness
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Orthopnea.......pillows
                          (s)
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Paroxysmal nocturnal
                          dyspnea (PND)
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Signs
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Regular 40min (Regular)
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> BP 40mm (HG)
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Elevated jugular venous
                          pressure
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Divider sx={{ mb: 2 }} />
          <HeadingTitle text={"HF Aetiology"} />
          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Cardiovascular
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Ischaemic: (ACS)
                          <br /> Post PCI (Yes) <br /> Post CABG (Yes)
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Cardiomyopathy :
                          (Hypertrophic )
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Dilated
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Cardiovascular
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Pregnancy- PPMC : (Yes)
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Pulmonary : RHF
                          (Pulmonary Hypertension)
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Infective (HIV and Viral
                          Cardiomyopathy)
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                  style={{ marginBottom: "0px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Others
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Stage- &ensp;C
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} />
                          NYHA- &ensp; I
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Hospitalization Times-
                          &ensp; 1year
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderTop: "none",
                  }}
                  style={{ marginBottom: "0px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <Table
                  className="middle_divied_table"
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderTop: "none",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        First Date
                      </TableCell>
                      <TableCell width="50%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Second Date
                      </TableCell>
                      <TableCell width="50%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="50%" size="small">
                        Third Date
                      </TableCell>
                      <TableCell width="50%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Risk Factors
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> No Pre-exisiting
                          Condition
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> HTN
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> DM Type
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Cardiovascular
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Asthma/BA
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> COPD
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Renal Failure
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderBottom: "none",
                  }}
                  style={{ marginBottom: "0px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Treatment History
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <Table
                  className="middle_divied_table"
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderTop: "none",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Drug
                      </TableCell>
                      <TableCell width="80%" size="small">
                        Problem 1
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Dose
                      </TableCell>
                      <TableCell width="80%" size="small">
                        Problem 2
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Frequency
                      </TableCell>
                      <TableCell width="80%" size="small">
                        Problem 3
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Duration
                      </TableCell>
                      <TableCell width="80%" size="small">
                        Problem 4
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        Device(ICD)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="30%" size="small">
                        ICD Date
                      </TableCell>
                      <TableCell width="70%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        align="center"
                        size="small"
                        component={"th"}
                      >
                        Others Descriptive (Drug related)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} size="small">
                        Your Problem
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Precipittating Factor for HF
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Poor Compliance
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Ongoing ischaemia
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Arrhythmia: AF
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Medications
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Comorbidities
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> NSAID
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Steroid
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        Heart failure diagnosis date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="80%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Non HF problem list
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> HF Problem List 1
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> HF Problem List 2
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> HF Problem List 3
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Non HF Medication list
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Non HF Problem List 1
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Non HF Problem List 2
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Non HF Problem List 3
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />
          <HeadingTitle text={"Blood Biomarker"} />
          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="60%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        HB (g/dl)
                      </TableCell>
                      <TableCell width="60%" size="small">
                        20g
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Blood Sugar
                      </TableCell>
                      <TableCell width="60%" size="small">
                        50
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Na (mmo1/L)
                      </TableCell>
                      <TableCell width="60%" size="small">
                        30mmo1/L
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        K (mmo1/L)
                      </TableCell>
                      <TableCell width="60%" size="small">
                        20mmo1/L
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Mg (mmo1/L)
                      </TableCell>
                      <TableCell width="60%" size="small">
                        25mmo1/L
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        S Creat (mg%)
                      </TableCell>
                      <TableCell width="60%" size="small">
                        25mg
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        BUN (mg%)
                      </TableCell>
                      <TableCell width="60%" size="small">
                        25mg
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="60%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Trop- I
                      </TableCell>
                      <TableCell width="60%" size="small">
                        20
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        INR
                      </TableCell>
                      <TableCell width="60%" size="small">
                        50
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        APTT
                      </TableCell>
                      <TableCell width="60%" size="small">
                        30
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        TSH
                      </TableCell>
                      <TableCell width="60%" size="small">
                        20
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        HBA1c
                      </TableCell>
                      <TableCell width="60%" size="small">
                        25
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        eGFR
                      </TableCell>
                      <TableCell width="60%" size="small">
                        25
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="60%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        CT Angiogram
                      </TableCell>
                      <TableCell width="60%" size="small">
                        20
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        S Iron
                      </TableCell>
                      <TableCell width="60%" size="small">
                        50
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Ferritin
                      </TableCell>
                      <TableCell width="60%" size="small">
                        30
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        TIBC
                      </TableCell>
                      <TableCell width="60%" size="small">
                        20
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Chest X-ray
                      </TableCell>
                      <TableCell width="60%" size="small">
                        25
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        Lipid Profile (Yes)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="80%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        TC
                      </TableCell>
                      <TableCell width="80%" size="small">
                        20
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        TG
                      </TableCell>
                      <TableCell width="80%" size="small">
                        25
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        LDL
                      </TableCell>
                      <TableCell width="80%" size="small">
                        40
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        HDL
                      </TableCell>
                      <TableCell width="80%" size="small">
                        35
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        Angiogram (Yes)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="80%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        LM
                      </TableCell>
                      <TableCell width="80%" size="small">
                        20
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        TVD
                      </TableCell>
                      <TableCell width="80%" size="small">
                        25
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        DVD
                      </TableCell>
                      <TableCell width="80%" size="small">
                        40
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        SVD
                      </TableCell>
                      <TableCell width="80%" size="small">
                        35
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        NT-Pro BNP(Yes)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="80%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Finding
                      </TableCell>
                      <TableCell width="80%" size="small">
                        Finding Text
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        ECG (Yes)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="80%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Finding
                      </TableCell>
                      <TableCell width="80%" size="small">
                        Finding Text
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        ECHO (Yes)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="80%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        LA
                      </TableCell>
                      <TableCell width="80%" size="small">
                        30
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        LVIDd
                      </TableCell>
                      <TableCell width="80%" size="small">
                        20
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        LVIDs
                      </TableCell>
                      <TableCell width="80%" size="small">
                        40
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        LVEF
                      </TableCell>
                      <TableCell width="80%" size="small">
                        35
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">MR</TableCell>
                      <TableCell size="small">Gr-I</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">AR</TableCell>
                      <TableCell size="small">Gr-I</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">TR</TableCell>
                      <TableCell size="small">Gr-I</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} size="small">
                        Wall motion abnormality:
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            paddingLeft: "10px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Ongoing ischaemia
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            paddingLeft: "10px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Ant. Wall hypokinesia
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} size="small">
                        Others:(Others Text)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">MS</TableCell>
                      <TableCell size="small">Mild</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size="small">Daistolic dysfunction</TableCell>
                      <TableCell size="small">I</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} size="small">
                        PASP:(PASP Text)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} size="small">
                        Tissue doppler findings (GLS):(GLS Text)
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        6-minutes walk test (Yes)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="80%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Finding
                      </TableCell>
                      <TableCell width="80%" size="small">
                        Finding Text
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        Hotel/ Event Recorder (Yes)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small">Date</TableCell>
                      <TableCell size="small">12/12/2022</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={2} size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} />
                          PVC
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <CheckBoxIcon size={"15"} /> Ventricular
                          Arrhythmia(Yes)
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        LIR-4- Others (Yes)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Date
                      </TableCell>
                      <TableCell width="80%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Finding
                      </TableCell>
                      <TableCell width="80%" size="small">
                        Finding Text
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        Diagnosis
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ verticalAlign: "top" }}>
                      <TableCell width="20%" size="small">
                        Diagnosis
                      </TableCell>
                      <TableCell size="small">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Consectetur, aliquid.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        Staff Information
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Created by
                      </TableCell>
                      <TableCell width="60%" size="small">
                        Johan
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Assessed by
                      </TableCell>
                      <TableCell width="60%" size="small">
                        Mohammad
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" size="small">
                        Date of Assessment
                      </TableCell>
                      <TableCell width="60%" size="small">
                        12/12/2022
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  className="middle_divied_table"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center" size="small">
                        Doctor Review
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%" size="small">
                        Ratting
                      </TableCell>
                      <TableCell width="80%" size="small">
                        5
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ verticalAlign: "top" }}>
                      <TableCell width="20%" size="small">
                        Comment
                      </TableCell>
                      <TableCell width="80%" size="small">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Ex, quas.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>

        {afterViewContent && afterViewContent}
      </section>
    </>
  );
}

PatientViewPage.prototype = {
  isprintBtn: PropTypes.bool,
  afterViewContent: PropTypes.any,
};
PatientViewPage.defaultProps = {
  isprintBtn: true,
};

export default PatientViewPage;
