import * as React from "react";
import { useState} from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HeadingTitle from "../../Component/s/HeadingTitle";
import {
  Button,
  Grid,
  FormControl,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
  FormGroup,
  Checkbox,
  DialogActions,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Select from "react-select";
import * as Yup from "yup";
import {  useForm } from "react-hook-form";
import { Stack } from "@mui/system";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";
import PatientModal from "../../Component/Modal/PatientModal";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
const validationTreatmentModal = Yup.object().shape({
  selectProblem: Yup.string().required("Select Problem  is required"),
  problemName: Yup.string().required("Problem Name  is required"),
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//Ratting
const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}
//Data
const tabHeader = [
  {
    id: 0,
    label: "Basic Info",
  },
  {
    id: 1,
    label: "Patient History-1",
  },
  {
    id: 2,
    label: "Patient History-2",
  },
  {
    id: 3,
    label: "Patient History-3",
  },
  {
    id: 4,
    label: "Patient History-4",
  },
  {
    id: 5,
    label: "LIR-1",
  },
  {
    id: 6,
    label: "LIR-2",
  },
  {
    id: 7,
    label: "LIR-3",
  },
  {
    id: 8,
    label: "LIR-4",
  },
  {
    id: 9,
    label: "LIR-5",
  },
];
const patientIdData = [
  {
    id: 1,
    label: "Patient Id 1",
    value: "1",
  },
  {
    id: 2,
    label: "Patient Id 2",
    value: "2",
  },
  {
    id: 3,
    label: "Patient Id 3",
    value: "3",
  },
];
const problemList = [
  {
    id: 1,
    label: "label 1",
    value: "1",
  },
  {
    id: 2,
    label: "label 2",
    value: "2",
  },
  {
    id: 3,
    label: "label 3",
    value: "3",
  },
];
const dateList = [
  {
    id: 1,
    label: "First Date",
    value: "1",
  },
  {
    id: 2,
    label: "Second Date",
    value: "2",
  },
  {
    id: 3,
    label: "Third Date",
    value: "3",
  },
];

function AddPatientPage() {
  const [value, setValue] = useState(0);
  const [isClearable, setIsClearable] = useState(true);
  const [educationCondition, setEduCationCondition] = useState(false);
  const [edemaNoCondition, setEdemaNoCondition] = useState(false);
  const [edemaTraceCondition, setEdemaTraceCondition] = useState(false);
  const [liverCondition, setLiverTraceCondition] = useState(false);
  const [hfSoftCondition, setHfSoftCondition] = useState(false);
  const [hfLoundCondition, setLoundSoftCondition] = useState(false);
  const [lipidCondition, setLipidCondition] = useState(true);
  const [angiogramCondition, setAngiogramCondition] = useState(true);
  const [nonBnpCondition, setNonBnCondition] = useState(true);
  const [ecgCondition, setEcgCondition] = useState(true);
  const [echoCondition, setEchoCondition] = useState(true);
  const [minCondition, setMinCondition] = useState(true);
  const [eventCondition, setEventCondition] = useState(true);
  const [othersCondition, setOthersCondition] = useState(true);
  const [ventricularCondition, setVentricularCondition] = useState(false);
  const [atrialCondition, setAtrialCondition] = useState(false);
  const [deviceCondition, setDeviceCondition] = useState(true);
  const [symptomsOthers, setSymptomsOthers] = useState(false);
  const [cardiovascularOthers, setCardiovascularOthers] = useState(false);
  const [riskFactorOthers, setRiskFactorOthers] = useState(false);
  const [comorbiditesOthers, setComorbiditesOthers] = useState(false);
  const [rattingValue, setRattingValue] = useState(2);
  const [hover, setHover] = useState(-1);
  const [treatmentHistroyList, setTreatmentHistroyList] = useState([
    {
      id: 1,
      drug: "",
      dose: "",
      frequency: "",
      duration: "",
    },
  ]);
  const [hospitilizeDate, setHospitilizeDate] = useState([
    {
      id: 1,
      label: "First Date",
      date: dayjs().format("YYYY-MM-DD"),
    },
  ]);

  const [hfProblemList, setHfProblemList] = useState([
    {
      id: 1,
      value: "",
    },
  ]);
  const [hfMedicalList, setHfMedicalList] = useState([
    {
      id: 1,
      value: "",
    },
  ]);


  const [addHospitalCount, setAddHospitalCount] = useState(1);
  const [hospitalModal, setHospitalModal] = useState(false);

  const [patientData, setPatientData] = useState({
    patientName: "",
    enrollmentDate: null,
    address: "",
    careName: "",
    relationStatus: "",
    patientNumber: "",
    careNumber: "",
    patientAge: "",
    patientWaist: "",
    patientHeight: "",
    patientWeight: "",
    patientBMI: "",
    bloodGroup: "",
    gender: "",
    educationStatus: "",
    occupationStatus: "",
    monthIncome: "",
    symptomOthers: "",
    pluseInput: "",
    pulseStatus: "",
    bpInput: "",
    signElevated: false,
    signsEdema: "",
    signsEdemaValue: "",
    signsLiver: "",
    ascitesInput: "",
    signsLaterally: false,
    signsHS: "",
    signsS2Lound: "",
    signsThird: false,
    signsMurmur: false,
    signRales: "",
    ischaemicStatus: "",
    postPCI: "",
    postCABG: "",
    cardiomyopathyStatus: "",
    dilated: false,
    chemotherapeutic: false,
    valvular: false,
    rheumatic: false,
    hypertensive: false,
    inflammatory: false,
    cardiovascularOthers: "",
    pregnancyPPMC: "",
    pulmonaryCOPD: false,
    pulmonaryHypertension: false,
    infectiveHiv: false,
    metabolicStatus: "",
    stageStatus: "",
    hospitalizationTime: "",
    nynaStatus: "",
    riskOthers: "",
    comorbiditiesOthers: "",
    deviceStatus: "icd",
    deviceicdDate: null,
    devicectrDate: null,
    descriptiveOthersStatus: "drug_related",
    descriptiveInput: "",
    fluidStatus: "",
    hearFailureDate: null,
    bloodBiomarkerDate1: null,
    bloodHB: "",
    bloodSugar: "",
    bloodNammo1: "",
    bloodKmmo1: "",
    bloodMgmmo1: "",
    bloodSCreat: "",
    bloodBUN: "",
    bloodBiomarkerDate2: null,
    bloodTrop: "",
    bloodINR: "",
    bloodAPTT: "",
    bloodTSH: "",
    bloodHBA1c: "",
    bloodeGFR: "",
    bloodBiomarkerDate3: null,
    bloodAngiogram: "",
    bloodIron: "",
    bloodFerritin: "",
    bloodTIBC: "",
    bloodeChestxray: "",
    lipidProfileStatus: "yes",
    lipidDate: null,
    lipidtc: "",
    lipidtg: "",
    lipidldl: "",
    lipidhdl: "",
    angiogramStatus: "yes",
    angiogramDate: null,
    angiogramlm: "",
    angiogramtvd: "",
    angiogramdvd: "",
    angiogramsvd: "",
    ntroBnpStatus: "yes",
    ntroBnpDate: null,
    ntroBnpFinding: "",
    ecgStatus: "yes",
    ecgDate: null,
    ecgFinding: "",
    echoStatus: "yes",
    echoDate: null,
    echoLa: "",
    echoLavid: "",
    echoLavids: "",
    echoLvef: "",
    mrRadioButton: "",
    arRadioButton: "",
    trRadioButton: "",
    motionOthers: "",
    msRadioButton: "",
    paspInput: "",
    daistolicStatus: "",
    tissueInput: "",
    walkminutesStatus: "yes",
    walkDate: null,
    walkFinding: "",
    hotelRecorderStatus: "yes",
    hotelDate: null,
    hotelpvc: false,
    hotelVentricularStatus: "",
    hotelVentricularValue: "",
    atrialStatus: "no",
    atrialValue: "",
    heartRate: false,
    walkOtherStatus: "yes",
    walkOtherDate: null,
    walkOthersFinding: "",
    diagnosisComment: "",
    createdBy: "",
    assessedBy: "",
    assessmentDate: null,
    doctorReviewComment: "",
  });

  const [symptomsCheckBoxList, setSymptomsCheckBoxList] = useState([
    {
      id: 1,
      label: "Breathlessness",
      isChecked: false,
    },
    {
      id: 2,
      label: "Orthopnea.......pillows (s)",
      isChecked: false,
    },
    {
      id: 3,
      label: "Paroxysmal nocturnal dyspnea (PND)",
      isChecked: false,
    },
    {
      id: 4,
      label: "Fatigue, tiredness, increase time to recover after exercise",
      isChecked: false,
    },
    {
      id: 5,
      label: "Leg swelling",
      isChecked: false,
    },
    {
      id: 6,
      label: "Syncope",
      isChecked: false,
    },
    {
      id: 7,
      label: "Palpitation",
      isChecked: false,
    },
  ]);
  const [riskCheckBoxList, setRiskCheckBoxList] = useState([
    {
      id: 1,
      label: "No Pre-exisiting Condition",
      isChecked: false,
    },
    {
      id: 2,
      label: "HTN",
      isChecked: false,
    },
    {
      id: 3,
      label: "DM Type",
      isChecked: false,
    },
    {
      id: 4,
      label: "Dyslipidemia",
      isChecked: false,
    },
    {
      id: 5,
      label: "Positive FH",
      isChecked: false,
    },
    {
      id: 6,
      label: "Smoker",
      isChecked: false,
    },
    {
      id: 7,
      label: "Ex-Smoker",
      isChecked: false,
    },
    {
      id: 8,
      label: "Alcohol",
      isChecked: false,
    },
    {
      id: 9,
      label: "Obesity",
      isChecked: false,
    },
  ]);
  const [comorbiditiesCheckBoxList, setComorbiditiesCheckBoxList] = useState([
    {
      id: 1,
      label: "Asthma/BA",
      isChecked: false,
    },
    {
      id: 2,
      label: "COPD",
      isChecked: false,
    },
    {
      id: 3,
      label: "Renal Failure",
      isChecked: false,
    },
    {
      id: 4,
      label: "CLD",
      isChecked: false,
    },
    {
      id: 5,
      label: "Gout",
      isChecked: false,
    },
    {
      id: 6,
      label: "Thyroid Dysfunction",
      isChecked: false,
    },
    {
      id: 7,
      label: "Obstructive Sleep Apnoea",
      isChecked: false,
    },
    {
      id: 8,
      label: "Cerebrovascular accident",
      isChecked: false,
    },
    {
      id: 9,
      label: "Severe Musculoskeletal disease",
      isChecked: false,
    },
    {
      id: 10,
      label: "Cancer",
      isChecked: false,
    },
    {
      id: 11,
      label: "Bleeding diathesi",
      isChecked: false,
    },
    {
      id: 12,
      label: "Bleeding diathesis",
      isChecked: false,
    },
  ]);
  const [precipittatingCheckBoxList, setPrecipittatingCheckBoxList] = useState([
    {
      id: 1,
      label: "Poor Compliance",
      isChecked: false,
    },
    {
      id: 2,
      label: "Ongoing ischaemia",
      isChecked: false,
    },
    {
      id: 3,
      label: "Arrhythmia: AF",
      isChecked: false,
    },
    {
      id: 4,
      label: "Thyroid disease",
      isChecked: false,
    },
    {
      id: 5,
      label: "Trauma",
      isChecked: false,
    },
    {
      id: 6,
      label: "Pregnancy",
      isChecked: false,
    },
    {
      id: 7,
      label: "Infection",
      isChecked: false,
    },
    {
      id: 8,
      label: "Surgery",
      isChecked: false,
    },
    {
      id: 9,
      label: "Anemia",
      isChecked: false,
    },
    {
      id: 10,
      label: "Others (Specify)",
      isChecked: false,
    },
    {
      id: 11,
      label: "Salt",
      isChecked: false,
    },
  ]);
  const [medicationsCheckBoxList, setMedicationsCheckBoxList] = useState([
    {
      id: 1,
      label: "Comorbidities",
      isChecked: false,
    },
    {
      id: 2,
      label: "NSAID",
      isChecked: false,
    },
    {
      id: 3,
      label: "Steroid",
      isChecked: false,
    },
    {
      id: 4,
      label: "OCP",
      isChecked: false,
    },
    {
      id: 5,
      label: "Anti- Cancer Drugs",
      isChecked: false,
    },
    {
      id: 6,
      label: "Anti- Depressant Drugs",
      isChecked: false,
    },
  ]);
  const [motionCheckBoxList, setMotionCheckBoxList] = useState([
    {
      id: 1,
      label: "Global Hypokinesia",
      isChecked: false,
    },
    {
      id: 2,
      label: "Ant. Wall hypokinesia",
      isChecked: false,
    },
    {
      id: 3,
      label: "Inf. Wall Hypokinaesia",
      isChecked: false,
    },
  ]);
  const [hospitalModalData, setHospitalModalData] = useState({
    selectLabel: "",
    date: null,
  });

  //Get Patient Data
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = (data) => {
    console.log(
      "patient Data",patientData,
      "symptomsCheckBoxList Data",symptomsCheckBoxList,
      "hospitilizeDate Data", hospitilizeDate,
      "Risk CheckBoxList Data",riskCheckBoxList,
      "Comorbidities CheckBoxList Data",comorbiditiesCheckBoxList,
      "Treatment HistroyList Data", treatmentHistroyList,
      "Precipittating CheckBoxList Data",precipittatingCheckBoxList,
      "Medications CheckBoxList Data",medicationsCheckBoxList,
      "Hf ProblemList Data",hfProblemList,
      "Hf MedicalList Data",hfMedicalList,
      "Ratting Value Data",rattingValue,
      "Patient Data Submit"
    );
  };

  //Hospital Submit
  const onSubmitHospital = (e) => {
    e.preventDefault();
    setHospitilizeDate([
      ...hospitilizeDate,
      {
        id: uuidv4(),
        label: hospitalModalData?.selectLabel,
        date: dayjs(hospitalModalData?.date).format("YYYY-MM-DD"),
      },
    ]);
    hospitalModalData.date = "";
  };

  //Patient Data Handle Change
  const handleChangePatient = (value, key, isDate = false) => {
    if (isDate === true) {
      const formatDate = dayjs(value).format("YYYY-MM-DD");
      const newPatientData = { ...patientData };
      newPatientData[key] = formatDate;
      setPatientData(newPatientData);
    } else {
      const newPatientData = { ...patientData };
      newPatientData[key] = value;
      setPatientData(newPatientData);
    }
  };
  //Uncheck Radio Button
  const handleCheckRadioPatient = (value, key, stateObjectKey) => {
    if (value === stateObjectKey) {
      const newPatientData = { ...patientData };
      newPatientData[key] = "";
      setPatientData(newPatientData);
    }
  };
  // Checkbox
  const handleCheckbox = (id, index, stateKey, setStateKey) => {
    const changeCheckbox = [...stateKey];
    changeCheckbox[index].isChecked = !changeCheckbox[index].isChecked;
    setStateKey(
      stateKey.map((item) =>
        item.id === id ? { ...item, ...changeCheckbox[index] } : item
      )
    );
  };
//Single Change Handle
  const handleSingleChange = (e, index, stateObjectKey, setStateObjectKey) => {
    const { value } = e.target;
    const newList = [...stateObjectKey];
    newList[index].value = value;
    setStateObjectKey(newList);
  };
  //Hospitilize Change
  const handleChangeHospitalList = (date, index, id) => {
    const formatDate = dayjs(date).format("YYYY-MM-DD");
    const changeDate = [...hospitilizeDate];
    changeDate[index].date = formatDate;
    setHospitilizeDate(
      hospitilizeDate.map((item) =>
        item.id === id ? { ...item, ...changeDate[index] } : item
      )
    );
  };

  const handleChangeHospital = (value, key) => {
    const newHospitalModalData = { ...hospitalModalData };
    newHospitalModalData[key] = value;
    setHospitalModalData(newHospitalModalData);
  };
  const handleChangeHospitalDate = (value, key) => {
    const formatDate = dayjs(value).format("YYYY-MM-DD");
    const newHospitalModalData = { ...hospitalModalData };
    newHospitalModalData[key] = formatDate;
    setHospitalModalData(newHospitalModalData);
  };
  const handlePrevTab = () => {
    setValue(() => value - 1);
  };
  const handleNextTab = () => {
    setValue(() => value + 1);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //Treatment
  const handleChangeTreatmentHistory = (e, index) => {
    const { name, value } = e.target;
    const list = [...treatmentHistroyList];
    list[index][name] = value;
    setTreatmentHistroyList(list);
  };
  //Hospital List Remove
  const handleHospitalRemove = (id) => {
    setHospitilizeDate(hospitilizeDate.filter((item) => item.id !== id));
  };
  //Treatment List Add
  const handleAddTreatment = () => {
    setTreatmentHistroyList([
      ...treatmentHistroyList,
      {
        id: uuidv4(),
        drug: "",
        dose: "",
        frequency: "",
        duration: "",
      },
    ]);
  };
  //Treatment List Remove
  const handleTreatmentRemove = (id) => {
    setTreatmentHistroyList(
      treatmentHistroyList.filter((item) => item.id !== id)
    );
  };

  //Hospitilize
  const handleClickDateOpen = () => {
    setHospitalModal(true);
  };

  const handleDateClose = () => {
    setHospitalModal(false);
  };

  //HF Problem List Add
  const handleHfList = () => {
    setHfProblemList([
      ...hfProblemList,
      {
        id: uuidv4(),
        value:"",
      },
    ]);
  };
  //HF Problem List Remove
  const handleHfRemove = (id) => {
    setHfProblemList(hfProblemList.filter((item) => item.id !== id));
  };
  //HF Medical List Add
  const handleHfMedicalList = () => {
    setHfMedicalList([
      ...hfMedicalList,
      {
        id: uuidv4(),
        value:"",
      },
    ]);
  };
  //HF Medical List Remove
  const handleHfMedicalRemove = (id) => {
    setHfMedicalList(hfMedicalList.filter((item) => item.id !== id));
  };

  return (
    <>
    <Helmet>
       <title>Add Patient</title>
       <meta name="description" content="Web description" />
       <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
     </Helmet>
    <section className="add_page_wrapper">
      <form action="" key={1} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {tabHeader.map((tab, index) => (
                <Tab key={index} label={tab.label} {...a11yProps(index)} sx={{fontSize:"16px",fontWeight:"600",color:"black"}} />
              ))}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <HeadingTitle text={"HF Pattient Assessment"} />
            <Grid container spacing={2}>
              <Grid item lg={3} sm={6} xs={12}>
                <FormControl fullWidth variant="outlined" size="small">
                  <Typography>Patient Name*</Typography>

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Enter Name"
                    value={patientData?.patientName || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "patientName")
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <FormControl fullWidth variant="outlined" size="small">
                  <Typography>Enrollment date</Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={patientData.enrollmentDate}
                      inputFormat="DD/MM/YYYY"
                      onChange={(date) => {
                        handleChangePatient(date, "enrollmentDate", true);
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField size="small" {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 30 }} />
            <div style={{ marginTop: 30 }}>
              <HeadingTitle text={"Contact Information"} />
              <Grid container spacing={2}>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Address</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Adress"
                      value={patientData?.address || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "address")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Care Giver Name</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Care Giver Name"
                      value={patientData?.careName || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "careName")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Relation</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Relation Name"
                      value={patientData?.relationStatus || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "relationStatus")
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Patient Mobile Number</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Patient Mobile Number"
                      value={patientData?.patientNumber || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "patientNumber")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Care Give Mobile Number</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Care Mobile Number"
                      value={patientData?.careNumber || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "careNumber")
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <Divider style={{ marginTop: 30 }} />
            <div style={{ marginTop: 30 }}>
              <HeadingTitle text={"Demography"} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container columns={15} spacing={2}>
                    <Grid item sm={3} xs={6}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Age</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter Age"
                          value={patientData?.patientAge || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "patientAge")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Waist </Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter Waist "
                          value={patientData?.patientWaist || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "patientWaist")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Height</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter Height"
                          value={patientData?.patientHeight || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "patientHeight")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Weight</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter Weight"
                          value={patientData?.patientWeight || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "patientWeight")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>BMI</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter BMI"
                          value={patientData?.patientBMI || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "patientBMI")
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={8} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Blood Group</Typography>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={patientData?.bloodGroup || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodGroup")
                      }
                    >
                      <FormControlLabel
                        value="aplus"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "bloodGroup",
                                patientData?.bloodGroup
                              )
                            }
                          />
                        }
                        label="A+"
                      />
                      <FormControlLabel
                        value="aminus"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "bloodGroup",
                                patientData?.bloodGroup
                              )
                            }
                          />
                        }
                        label="A-"
                      />
                      <FormControlLabel
                        value="bplus"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "bloodGroup",
                                patientData?.bloodGroup
                              )
                            }
                          />
                        }
                        label="B+"
                      />
                      <FormControlLabel
                        value="bminus"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "bloodGroup",
                                patientData?.bloodGroup
                              )
                            }
                          />
                        }
                        label="B-"
                      />
                      <FormControlLabel
                        value="abplus"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "bloodGroup",
                                patientData?.bloodGroup
                              )
                            }
                          />
                        }
                        label="AB+"
                      />
                      <FormControlLabel
                        value="abminus"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "bloodGroup",
                                patientData?.bloodGroup
                              )
                            }
                          />
                        }
                        label="AB-"
                      />
                      <FormControlLabel
                        value="oplus"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "bloodGroup",
                                patientData?.bloodGroup
                              )
                            }
                          />
                        }
                        label="O+"
                      />
                      <FormControlLabel
                        value="ominus"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "bloodGroup",
                                patientData?.bloodGroup
                              )
                            }
                          />
                        }
                        label="O-"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: 10 }}>
              <FormControl fullWidth variant="outlined" size="small">
                <Typography>Sex</Typography>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={patientData?.gender || ""}
                  onChange={(e) =>
                    handleChangePatient(e.target.value, "gender")
                  }
                >
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio
                        onClick={(e) =>
                          handleCheckRadioPatient(
                            e.target.value,
                            "gender",
                            patientData?.gender
                          )
                        }
                      />
                    }
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={
                      <Radio
                        onClick={(e) =>
                          handleCheckRadioPatient(
                            e.target.value,
                            "gender",
                            patientData?.gender
                          )
                        }
                      />
                    }
                    label="Male"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ marginTop: 30 }}>
              <HeadingTitle text={"Socio Economical Status"} />
              <Stack spacing={2}>
                <FormControl fullWidth variant="outlined" size="small">
                  <Typography>Education Status:</Typography>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.educationStatus || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "educationStatus")
                    }
                  >
                    <FormControlLabel
                      value="literate"
                      control={
                        <Radio
                          onClick={(e) => {
                            setEduCationCondition(false);
                          }}
                        />
                      }
                      label="Literate"
                      onChange={() => {
                        setEduCationCondition(!educationCondition);
                      }}
                      checked={educationCondition === true}
                    />
                    {educationCondition && (
                      <>
                        <FormControlLabel
                          value="gradutate"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "educationStatus",
                                  patientData?.educationStatus
                                )
                              }
                            />
                          }
                          label="Gradutate"
                        />
                        <FormControlLabel
                          value="undergradutate"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "educationStatus",
                                  patientData?.educationStatus
                                )
                              }
                            />
                          }
                          label="Undergradutate"
                        />
                      </>
                    )}

                    <FormControlLabel
                      value="illiterate"
                      control={
                        <Radio
                          onClick={(e) =>
                            handleCheckRadioPatient(
                              e.target.value,
                              "educationStatus",
                              patientData?.educationStatus
                            )
                          }
                        />
                      }
                      label="Illiterate"
                      onChange={() => {
                        setEduCationCondition(false);
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl fullWidth variant="outlined" size="small">
                  <Typography>Type of Occupation:</Typography>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.occupationStatus || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "occupationStatus")
                    }
                  >
                    <FormControlLabel
                      value="sedentary"
                      control={
                        <Radio
                          onClick={(e) =>
                            handleCheckRadioPatient(
                              e.target.value,
                              "occupationStatus",
                              patientData?.occupationStatus
                            )
                          }
                        />
                      }
                      label="Sedentary"
                    />
                    <FormControlLabel
                      value="worker"
                      control={
                        <Radio
                          onClick={(e) =>
                            handleCheckRadioPatient(
                              e.target.value,
                              "occupationStatus",
                              patientData?.occupationStatus
                            )
                          }
                        />
                      }
                      label="Worker"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl fullWidth variant="outlined" size="small">
                  <Typography>Month Income</Typography>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.monthIncome || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "monthIncome")
                    }
                  >
                    <FormControlLabel
                      value="lowSallary"
                      control={
                        <Radio
                          onClick={(e) =>
                            handleCheckRadioPatient(
                              e.target.value,
                              "monthIncome",
                              patientData?.monthIncome
                            )
                          }
                        />
                      }
                      label="<30000BDT"
                    />
                    <FormControlLabel
                      value="mediumSallary"
                      control={
                        <Radio
                          onClick={(e) =>
                            handleCheckRadioPatient(
                              e.target.value,
                              "monthIncome",
                              patientData?.monthIncome
                            )
                          }
                        />
                      }
                      label="30000-80000BDT"
                    />
                    <FormControlLabel
                      value="highSallary"
                      control={
                        <Radio
                          onClick={(e) =>
                            handleCheckRadioPatient(
                              e.target.value,
                              "monthIncome",
                              patientData?.monthIncome
                            )
                          }
                        />
                      }
                      label=">30000BDT"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div>
              <HeadingTitle text={"Complaints"} />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography className="symptoms_title">Symptoms</Typography>
                  <FormGroup>
                    {symptomsCheckBoxList.map((item, index) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() =>
                                handleCheckbox(
                                  item?.id,
                                  index,
                                  symptomsCheckBoxList,
                                  setSymptomsCheckBoxList
                                )
                              }
                              checked={symptomsCheckBoxList[index]?.isChecked}
                            />
                          }
                          label={item?.label}
                          key={index}
                        />
                      );
                    })}

                    <FormControlLabel
                      control={<Checkbox />}
                      label="Others"
                      onChange={() => setSymptomsOthers(!symptomsOthers)}
                    />
                  </FormGroup>
                  {symptomsOthers && (
                    <FormControl fullWidth size="small">
                      <TextField
                        id="outlined-basic"
                        placeholder="Discribe your problem"
                        variant="outlined"
                        value={patientData?.symptomOthers || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "symptomOthers")
                        }
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={6}>
                  <Typography className="symptoms_title">Signs</Typography>
                  <FormGroup>
                    <div className="group_checkbox_area">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.pulseStatus?.length > 0 ||
                              patientData?.pluseInput?.length > 0
                                ? true
                                : false
                            }
                          />
                        }
                        label="Pulse"
                      />
                      <div className="pulse_input">
                        <TextField
                          className="group_check_input"
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          value={patientData?.pluseInput || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "pluseInput")
                          }
                        />
                        <p>min</p>
                      </div>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.pulseStatus || ""}
                        onChange={(e) => {
                          handleChangePatient(e.target.value, "pulseStatus");
                        }}
                      >
                        <FormControlLabel
                          value="regular"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "pulseStatus",
                                  patientData?.pulseStatus
                                )
                              }
                            />
                          }
                          label="Regular"
                        />
                        <FormControlLabel
                          value="irregular"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "pulseStatus",
                                  patientData?.pulseStatus
                                )
                              }
                            />
                          }
                          label="Irregular"
                        />
                      </RadioGroup>
                    </div>
                    <div className="group_checkbox_area">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.bpInput?.length > 0 ? true : false
                            }
                          />
                        }
                        label="BP"
                      />
                      <div className="pulse_input">
                        <TextField
                          className="group_check_input"
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          value={patientData?.bpInput || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "bpInput")
                          }
                        />
                        <p>mm (HG)</p>
                      </div>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.signElevated}
                          onChange={(e) => {
                            handleChangePatient(
                              e.target.checked,
                              "signElevated"
                            );
                          }}
                        />
                      }
                      label="Elevated jugular venous pressure"
                    />
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.signsEdema.length > 0 ? true : false
                            }
                          />
                        }
                        label="Edema"
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.signsEdema || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "signsEdema")
                        }
                      >
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "signsEdema",
                                  patientData?.signsEdema
                                )
                              }
                            />
                          }
                          label="No"
                          onChange={() => {
                            setEdemaNoCondition(true);
                            setEdemaTraceCondition(false);
                          }}
                        />
                        <FormControlLabel
                          value="trace"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "signsEdema",
                                  patientData?.signsEdema
                                )
                              }
                            />
                          }
                          label="trace"
                          onChange={() => {
                            setEdemaTraceCondition(true);
                          }}
                        />
                      </RadioGroup>
                      {edemaTraceCondition && (
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={patientData?.signsEdemaValue || ""}
                          onChange={(e) =>
                            handleChangePatient(
                              e.target.value,
                              "signsEdemaValue"
                            )
                          }
                        >
                          <FormControlLabel
                            value="1"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "signsEdemaValue",
                                    patientData?.signsEdemaValue
                                  )
                                }
                              />
                            }
                            label="1+"
                          />
                          <FormControlLabel
                            value="2"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "signsEdemaValue",
                                    patientData?.signsEdemaValue
                                  )
                                }
                              />
                            }
                            label="2+"
                          />
                          <FormControlLabel
                            value="3"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "signsEdemaValue",
                                    patientData?.signsEdemaValue
                                  )
                                }
                              />
                            }
                            label="3+"
                          />
                          <FormControlLabel
                            value="4"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "signsEdemaValue",
                                    patientData?.signsEdemaValue
                                  )
                                }
                              />
                            }
                            label="4+"
                          />
                        </RadioGroup>
                      )}
                    </div>

                    <div>
                      <FormControlLabel control={<Checkbox />} label="Anemia" />
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.signsLiver.length > 0 ? true : false
                            }
                          />
                        }
                        label="Liver"
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.signsLiver || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "signsLiver")
                        }
                      >
                        <FormControlLabel
                          value="normal"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "signsLiver",
                                  patientData?.signsLiver
                                )
                              }
                              onChange={() => {
                                setLiverTraceCondition(true);
                              }}
                            />
                          }
                          label="Normal"
                        />
                        <FormControlLabel
                          value="palpable"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "signsLiver",
                                  patientData?.signsLiver
                                )
                              }
                              onChange={() => {
                                setLiverTraceCondition(true);
                              }}
                            />
                          }
                          label="Palpable"
                        />
                      </RadioGroup>
                    </div>
                    <div className="group_checkbox_area">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.ascitesInput.length > 0
                                ? true
                                : false
                            }
                          />
                        }
                        label="Ascites"
                      />
                      <div className="pulse_input">
                        <TextField
                          className="group_check_input"
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          value={patientData?.ascitesInput || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "ascitesInput")
                          }
                        />
                      </div>
                    </div>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.signsLaterally}
                          onChange={(e) => {
                            handleChangePatient(
                              e.target.checked,
                              "signsLaterally"
                            );
                          }}
                        />
                      }
                      label="Laterally displaced apical impulse "
                    />

                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.signsHS.length > 0 ||
                              patientData?.signsS2Lound.length > 0
                                ? true
                                : false
                            }
                          />
                        }
                        label="HS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.signsHS.length > 0 ? true : false
                            }
                          />
                        }
                        label="S1-Soft"
                      />

                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.signsHS || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "signsHS")
                        }
                      >
                        <FormControlLabel
                          value="normal"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "signsHS",
                                  patientData?.signsHS
                                )
                              }
                              onChange={() => {
                                setHfSoftCondition(true);
                              }}
                            />
                          }
                          label="Normal"
                        />
                      </RadioGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.signsS2Lound.length > 0
                                ? true
                                : false
                            }
                          />
                        }
                        label="S2-Loud"
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.signsS2Lound || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "signsS2Lound")
                        }
                      >
                        <FormControlLabel
                          value="normal"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "signsS2Lound",
                                  patientData?.signsS2Lound
                                )
                              }
                              onChange={() => {
                                setLoundSoftCondition(true);
                              }}
                            />
                          }
                          label="Normal"
                        />
                      </RadioGroup>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.signsThird}
                          onChange={(e) => {
                            handleChangePatient(e.target.checked, "signsThird");
                          }}
                        />
                      }
                      label="Third heart sound (gallop rhythm)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.signsMurmur}
                          onChange={(e) => {
                            handleChangePatient(
                              e.target.checked,
                              "signsMurmur"
                            );
                          }}
                        />
                      }
                      label="Murmur"
                    />

                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.signRales.length > 0 ? true : false
                            }
                          />
                        }
                        label="Rales"
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.signRales || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "signRales")
                        }
                      >
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "signRales",
                                  patientData?.signRales
                                )
                              }
                            />
                          }
                          label="<= 1/2"
                        />
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "signRales",
                                  patientData?.signRales
                                )
                              }
                            />
                          }
                          label="<1/2"
                        />
                      </RadioGroup>
                    </div>
                  </FormGroup>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div>
              <HeadingTitle text={"HF Aetiology"} />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography className="symptoms_title">
                    Cardiovascular
                  </Typography>
                  <FormGroup>
                    <div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                patientData?.ischaemicStatus.length > 0 ||
                                patientData?.postPCI.length > 0 ||
                                patientData?.postCABG.length > 0
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Ischaemic:"
                        />
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={patientData?.ischaemicStatus || ""}
                          onChange={(e) =>
                            handleChangePatient(
                              e.target.value,
                              "ischaemicStatus"
                            )
                          }
                        >
                          <FormControlLabel
                            value="acs"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "ischaemicStatus",
                                    patientData?.ischaemicStatus
                                  )
                                }
                              />
                            }
                            label="ACS"
                          />
                          <FormControlLabel
                            value="ccs"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "ischaemicStatus",
                                    patientData?.ischaemicStatus
                                  )
                                }
                              />
                            }
                            label="CCS"
                          />
                        </RadioGroup>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "10px",
                          paddingLeft: "44px",
                        }}
                      >
                        <Typography variant="body1">Post PCI -</Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={patientData?.postPCI || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "postPCI")
                          }
                        >
                          <FormControlLabel
                            value="yes"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "postPCI",
                                    patientData?.postPCI
                                  )
                                }
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "postPCI",
                                    patientData?.postPCI
                                  )
                                }
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "10px",
                          paddingLeft: "44px",
                        }}
                      >
                        <Typography variant="body1">Post CABG -</Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={patientData?.postCABG || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "postCABG")
                          }
                        >
                          <FormControlLabel
                            value="yes"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "postCABG",
                                    patientData?.postCABG
                                  )
                                }
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "postCABG",
                                    patientData?.postCABG
                                  )
                                }
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.cardiomyopathyStatus.length > 0
                                ? true
                                : false
                            }
                          />
                        }
                        label="Cardiomyopathy :"
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.cardiomyopathyStatus || ""}
                        onChange={(e) =>
                          handleChangePatient(
                            e.target.value,
                            "cardiomyopathyStatus"
                          )
                        }
                      >
                        <FormControlLabel
                          value="hypertrophic"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "cardiomyopathyStatus",
                                  patientData?.cardiomyopathyStatus
                                )
                              }
                            />
                          }
                          label="Hypertrophic"
                        />
                        <FormControlLabel
                          value="restrictive"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "cardiomyopathyStatus",
                                  patientData?.cardiomyopathyStatus
                                )
                              }
                            />
                          }
                          label="Restrictive"
                        />
                      </RadioGroup>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.dilated}
                          onChange={(e) => {
                            handleChangePatient(e.target.checked, "dilated");
                          }}
                        />
                      }
                      label="Dilated"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.chemotherapeutic}
                          onChange={(e) => {
                            handleChangePatient(
                              e.target.checked,
                              "chemotherapeutic"
                            );
                          }}
                        />
                      }
                      label="Chemotherapeutic"
                    />
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={patientData?.valvular}
                            onChange={(e) => {
                              handleChangePatient(e.target.checked, "valvular");
                            }}
                          />
                        }
                        label="Valvular"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={patientData?.rheumatic}
                            onChange={(e) => {
                              handleChangePatient(
                                e.target.checked,
                                "rheumatic"
                              );
                            }}
                          />
                        }
                        label="Rheumatic heart disease"
                      />
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.hypertensive}
                          onChange={(e) => {
                            handleChangePatient(
                              e.target.checked,
                              "hypertensive"
                            );
                          }}
                        />
                      }
                      label="Hypertensive"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.inflammatory}
                          onChange={(e) => {
                            handleChangePatient(
                              e.target.checked,
                              "inflammatory"
                            );
                          }}
                        />
                      }
                      label="Inflammatory Cardiomyopathy"
                    />

                    <FormControlLabel
                      control={<Checkbox />}
                      label="Others"
                      onChange={() =>
                        setCardiovascularOthers(!cardiovascularOthers)
                      }
                    />
                  </FormGroup>
                  {cardiovascularOthers && (
                    <FormControl fullWidth size="small">
                      <TextField
                        id="outlined-basic"
                        placeholder="Discribe your problem"
                        variant="outlined"
                        value={patientData?.cardiovascularOthers || ""}
                        onChange={(e) =>
                          handleChangePatient(
                            e.target.value,
                            "cardiovascularOthers"
                          )
                        }
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={6}>
                  <Typography className="symptoms_title"></Typography>
                  <FormGroup>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      <Typography variant="body1">Pregnancy- PPMC :</Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.pregnancyPPMC || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "pregnancyPPMC")
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "pregnancyPPMC",
                                  patientData?.pregnancyPPMC
                                )
                              }
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "pregnancyPPMC",
                                  patientData?.pregnancyPPMC
                                )
                              }
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      Pulmonary : RHF
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.pulmonaryCOPD}
                          onChange={(e) => {
                            handleChangePatient(
                              e.target.checked,
                              "pulmonaryCOPD"
                            );
                          }}
                        />
                      }
                      label="COPD"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.pulmonaryHypertension}
                          onChange={(e) => {
                            handleChangePatient(
                              e.target.checked,
                              "pulmonaryHypertension"
                            );
                          }}
                        />
                      }
                      label="Pulmonary Hypertension"
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      Infective
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={patientData?.infectiveHiv}
                          onChange={(e) => {
                            handleChangePatient(
                              e.target.checked,
                              "infectiveHiv"
                            );
                          }}
                        />
                      }
                      label="HIV and Viral Cardiomyopathy"
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      Toxic (Alcohol, Cocaine)
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      Metabolic
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.metabolicStatus || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "metabolicStatus")
                        }
                      >
                        <FormControlLabel
                          value="diseases"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "metabolicStatus",
                                  patientData?.metabolicStatus
                                )
                              }
                            />
                          }
                          label="Diseases"
                        />
                        <FormControlLabel
                          value="hemochrornatosis"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "metabolicStatus",
                                  patientData?.metabolicStatus
                                )
                              }
                            />
                          }
                          label="Hemochrornatosis"
                        />
                        <FormControlLabel
                          value="wilson_disease"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "metabolicStatus",
                                  patientData?.metabolicStatus
                                )
                              }
                            />
                          }
                          label="Wilson's disease"
                        />
                      </RadioGroup>
                    </FormControl>
                  </FormGroup>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 30 }} />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography
                    className="symptoms_title"
                    style={{ marginTop: 30, marginBottom: "0px !important" }}
                  >
                    Stage
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={patientData?.stageStatus || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "stageStatus")
                      }
                    >
                      <FormControlLabel
                        value="a"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "stageStatus",
                                patientData?.stageStatus
                              )
                            }
                          />
                        }
                        label="A"
                      />
                      <FormControlLabel
                        value="b"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "stageStatus",
                                patientData?.stageStatus
                              )
                            }
                          />
                        }
                        label="B"
                      />
                      <FormControlLabel
                        value="c"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "stageStatus",
                                patientData?.stageStatus
                              )
                            }
                          />
                        }
                        label="C"
                      />
                      <FormControlLabel
                        value="d"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "stageStatus",
                                patientData?.stageStatus
                              )
                            }
                          />
                        }
                        label="D"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Divider style={{ marginTop: 30 }} />
                  <Typography
                    className="symptoms_title"
                    style={{ marginTop: 30, marginBottom: "0px !important" }}
                  >
                    Hospitalization
                  </Typography>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Times in 1 year</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Time"
                      value={patientData?.hospitalizationTime || ""}
                      onChange={(e) =>
                        handleChangePatient(
                          e.target.value,
                          "hospitalizationTime"
                        )
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    className="symptoms_title"
                    style={{ marginTop: 30, marginBottom: "0px !important" }}
                  >
                    NYHA
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={patientData?.nynaStatus || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "nynaStatus")
                      }
                    >
                      <FormControlLabel
                        value="I"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "nynaStatus",
                                patientData?.nynaStatus
                              )
                            }
                          />
                        }
                        label="I"
                      />
                      <FormControlLabel
                        value="II"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "nynaStatus",
                                patientData?.nynaStatus
                              )
                            }
                          />
                        }
                        label="II"
                      />
                      <FormControlLabel
                        value="III"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "nynaStatus",
                                patientData?.nynaStatus
                              )
                            }
                          />
                        }
                        label="III"
                      />
                      <FormControlLabel
                        value="IV"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "nynaStatus",
                                patientData?.nynaStatus
                              )
                            }
                          />
                        }
                        label="IV"
                      />
                      <FormControlLabel
                        value="V"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "nynaStatus",
                                patientData?.nynaStatus
                              )
                            }
                          />
                        }
                        label="V"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Divider style={{ marginTop: 30 }} />
                  <Typography
                    className="symptoms_title"
                    style={{ marginTop: 30, marginBottom: "0px !important" }}
                  >
                    Date of last 3 Hospitalizatio
                  </Typography>

                  {hospitilizeDate.length > 0 ? (
                    <Grid container spacing={2}>
                      {hospitilizeDate.map((item, index) => {
                        return (
                          <Grid item lg={6} xs={12} key={item.id}>
                            <Typography>{item.label} </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={item?.date}
                                inputFormat="DD/MM/YYYY"
                                onChange={(date) => {
                                  handleChangeHospitalList(
                                    date,
                                    index,
                                    item.id
                                  );
                                }}
                                fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    fullWidth
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            {hospitilizeDate?.length > 1 && (
                              <Box sx={{ marginTop: "10px" }}>
                                <Button
                                  variant="contained"
                                  startIcon={<DeleteIcon />}
                                  onClick={() => handleHospitalRemove(item.id)}
                                >
                                  Remove
                                </Button>
                              </Box>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Typography variant="h6">None</Typography>
                  )}
                  {hospitilizeDate?.length < 3 && (
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <Box sx={{ marginTop: "25px" }}>
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleClickDateOpen}
                          >
                            Add
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <PatientModal
                handleOpen={hospitalModal}
                handleClose={handleDateClose}
                modalTittle="Add New Date"
                modalTopText={
                  " Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam, maiores! "
                }
                modalBody={
                  <form onSubmit={onSubmitHospital}>
                    <Stack>
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <Typography>Select Date Number*</Typography>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={""}
                          isClearable={isClearable}
                          name="color"
                          placeholder={"Select Date"}
                          options={dateList.map((item) => {
                            return {
                              value: item?.value,
                              label: item?.label,
                            };
                          })}
                          onChange={(selectedOption) => {
                            handleChangeHospital(
                              selectedOption?.label,
                              "selectLabel"
                            );
                          }}
                        />
                        <Stack>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            size="small"
                            sx={{ mt: 2 }}
                          >
                            <Typography>Select Date</Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                onChange={(date) => {
                                  handleChangeHospitalDate(date, "date");
                                }}
                                value={dayjs(hospitalModalData?.date).format(
                                  "YYYY-MM-DD"
                                )}
                                inputFormat="DD/MM/YYYY"
                                fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    fullWidth
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Stack>
                      </FormControl>
                    </Stack>

                    <Divider sx={{ mt: 2 }} />

                    <DialogActions>
                      <Button onClick={handleDateClose}>Cancel</Button>
                      <Button type="submit" onClick={handleDateClose}>
                        Add
                      </Button>
                    </DialogActions>
                  </form>
                }
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography className="symptoms_title">
                    Risk Factors
                  </Typography>
                  <FormGroup>
                    {riskCheckBoxList.map((item, index) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() =>
                                handleCheckbox(
                                  item?.id,
                                  index,
                                  riskCheckBoxList,
                                  setRiskCheckBoxList
                                )
                              }
                              checked={riskCheckBoxList[index]?.isChecked}
                            />
                          }
                          label={item?.label}
                          key={index}
                        />
                      );
                    })}

                    <FormControlLabel
                      control={<Checkbox />}
                      label="Others"
                      onChange={() => setRiskFactorOthers(!riskFactorOthers)}
                    />
                  </FormGroup>
                  {riskFactorOthers && (
                    <FormControl fullWidth size="small">
                      <TextField
                        id="outlined-basic"
                        placeholder="Discribe your problem"
                        variant="outlined"
                        value={patientData?.riskOthers || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "riskOthers")
                        }
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="symptoms_title">
                    Comorbidities
                  </Typography>
                  <FormGroup>
                    {comorbiditiesCheckBoxList.map((item, index) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() =>
                                handleCheckbox(
                                  item?.id,
                                  index,
                                  comorbiditiesCheckBoxList,
                                  setComorbiditiesCheckBoxList
                                )
                              }
                              checked={
                                comorbiditiesCheckBoxList[index]?.isChecked
                              }
                            />
                          }
                          label={item?.label}
                          key={index}
                        />
                      );
                    })}

                    <FormControlLabel
                      control={<Checkbox />}
                      label="Others"
                      onChange={() =>
                        setComorbiditesOthers(!comorbiditesOthers)
                      }
                    />
                  </FormGroup>
                  {comorbiditesOthers && (
                    <FormControl fullWidth size="small">
                      <TextField
                        id="outlined-basic"
                        placeholder="Discribe your problem"
                        variant="outlined"
                        value={patientData?.comorbiditiesOthers || ""}
                        onChange={(e) =>
                          handleChangePatient(
                            e.target.value,
                            "comorbiditiesOthers"
                          )
                        }
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Divider style={{ marginTop: 30 }} />
                  <div style={{ marginTop: 30 }}>
                    <HeadingTitle text={"Treatment History"} />

                    {treatmentHistroyList.map((item, index) => {
                      return (
                        <Grid
                          container
                          columns={14}
                          spacing={2}
                          key={item.id}
                          style={{ marginBottom: "15px" }}
                        >
                          <Grid item lg={3} sm={6} xs={12} key={item.id}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                            >
                              <Typography>Drug </Typography>

                              <TextField
                                id={`drug`}
                                variant="outlined"
                                size="small"
                                name="drug"
                                placeholder={"Enter Drug Name"}
                                value={item?.drug || ""}
                                onChange={(e) =>
                                  handleChangeTreatmentHistory(e, index)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={3} sm={6} xs={12}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                            >
                              <Typography>Dose </Typography>

                              <TextField
                                id={`dose`}
                                variant="outlined"
                                size="small"
                                name="dose"
                                placeholder={"Enter Dose Name"}
                                value={item?.dose || ""}
                                onChange={(e) =>
                                  handleChangeTreatmentHistory(e, index)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={3} sm={6} xs={12}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                            >
                              <Typography>Frequency </Typography>

                              <TextField
                                id={`frequency`}
                                variant="outlined"
                                size="small"
                                name="frequency"
                                placeholder={"Enter Frequency Name"}
                                value={item?.frequency || ""}
                                onChange={(e) =>
                                  handleChangeTreatmentHistory(e, index)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={3} sm={6} xs={12}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                            >
                              <Typography>Duration </Typography>

                              <TextField
                                id={`duration`}
                                variant="outlined"
                                size="small"
                                name="duration"
                                value={item?.duration || ""}
                                placeholder={"Enter Duration Name"}
                                onChange={(e) =>
                                  handleChangeTreatmentHistory(e, index)
                                }
                              />
                            </FormControl>
                          </Grid>
                          {treatmentHistroyList?.length > 1 && (
                            <Grid item lg={2} sm={6} xs={12}>
                              <Box sx={{ marginTop: { lg: "26px" } }}>
                                <Button
                                  variant="contained"
                                  startIcon={<DeleteIcon />}
                                  type="button"
                                  onClick={() =>
                                    handleTreatmentRemove(item?.id)
                                  }
                                >
                                  Remove
                                </Button>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}

                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Box sx={{ marginTop: "40px", marginLeft: "18px" }}>
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleAddTreatment}
                          >
                            Add
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Divider style={{ marginTop: 30 }} />
                  <div style={{ marginTop: 30 }}>
                    <div className="title_radio_btn_area">
                      <HeadingTitle text={"Device"} />
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={patientData?.deviceStatus || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "deviceStatus")
                          }
                        >
                          <FormControlLabel
                            value="icd"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "deviceStatus",
                                    patientData?.deviceStatus
                                  )
                                }
                              />
                            }
                            label="ICD"
                            onChange={() => setDeviceCondition(true)}
                          />
                          <FormControlLabel
                            value="crt"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "deviceStatus",
                                    patientData?.deviceStatus
                                  )
                                }
                              />
                            }
                            label="CRT"
                            onChange={() => setDeviceCondition(false)}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          size="small"
                          d
                        >
                          <Typography>ICD</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={patientData.deviceicdDate}
                              inputFormat="DD/MM/YYYY"
                              onChange={(date) => {
                                handleChangePatient(
                                  date,
                                  "deviceicdDate",
                                  true
                                );
                              }}
                              fullWidth
                              disabled={deviceCondition === false}
                              renderInput={(params) => (
                                <TextField size="small" {...params} fullWidth />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <Typography>CRT</Typography>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={patientData.devicectrDate}
                              inputFormat="DD/MM/YYYY"
                              onChange={(date) => {
                                handleChangePatient(
                                  date,
                                  "devicectrDate",
                                  true
                                );
                              }}
                              disabled={deviceCondition === true}
                              fullWidth
                              renderInput={(params) => (
                                <TextField size="small" {...params} fullWidth />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider style={{ marginTop: 30 }} />
                  <div style={{ marginTop: 30 }}>
                    <div className="title_radio_btn_area">
                      <HeadingTitle text={"Others ( Descriptive)"} />
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={patientData?.descriptiveOthersStatus || ""}
                          onChange={(e) =>
                            handleChangePatient(
                              e.target.value,
                              "descriptiveOthersStatus"
                            )
                          }
                        >
                          <FormControlLabel
                            value="drug_related"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "descriptiveOthersStatus",
                                    patientData?.descriptiveOthersStatus
                                  )
                                }
                              />
                            }
                            label="Drug related"
                          />
                          <FormControlLabel
                            value="device_related"
                            control={
                              <Radio
                                onClick={(e) =>
                                  handleCheckRadioPatient(
                                    e.target.value,
                                    "descriptiveOthersStatus",
                                    patientData?.descriptiveOthersStatus
                                  )
                                }
                              />
                            }
                            label="Device related"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            placeholder="Enter Others Descriptive  "
                            value={patientData?.descriptiveInput || ""}
                            onChange={(e) =>
                              handleChangePatient(
                                e.target.value,
                                "descriptiveInput"
                              )
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <div>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography className="symptoms_title">
                    Precipittating Factor for HF
                  </Typography>
                  <FormGroup>
                    {precipittatingCheckBoxList.map((item, index) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() =>
                                handleCheckbox(
                                  item?.id,
                                  index,
                                  precipittatingCheckBoxList,
                                  setPrecipittatingCheckBoxList
                                )
                              }
                              checked={
                                precipittatingCheckBoxList[index]?.isChecked
                              }
                            />
                          }
                          label={item?.label}
                          key={index}
                        />
                      );
                    })}

                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              patientData?.fluidStatus.length > 0 ? true : false
                            }
                          />
                        }
                        label="Fluid"
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.fluidStatus || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "fluidStatus")
                        }
                      >
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "fluidStatus",
                                  patientData?.fluidStatus
                                )
                              }
                            />
                          }
                          label="<IL"
                        />
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "fluidStatus",
                                  patientData?.fluidStatus
                                )
                              }
                            />
                          }
                          label=">IL"
                        />
                      </RadioGroup>
                    </div>
                  </FormGroup>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="symptoms_title">
                    Medications
                  </Typography>
                  <FormGroup>
                    {medicationsCheckBoxList.map((item, index) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() =>
                                handleCheckbox(
                                  item?.id,
                                  index,
                                  medicationsCheckBoxList,
                                  setMedicationsCheckBoxList
                                )
                              }
                              checked={
                                medicationsCheckBoxList[index]?.isChecked
                              }
                            />
                          }
                          label={item?.label}
                          key={index}
                        />
                      );
                    })}
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Divider style={{ marginTop: 30 }} />
                  <div style={{ marginTop: 30 }}>
                    <HeadingTitle text={"Heart failure diagnosis date"} />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <Typography>Date</Typography>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={patientData.hearFailureDate}
                              inputFormat="DD/MM/YYYY"
                              onChange={(date) => {
                                handleChangePatient(
                                  date,
                                  "hearFailureDate",
                                  true
                                );
                              }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField size="small" {...params} fullWidth />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider style={{ marginTop: 30 }} />
                  <div style={{ marginTop: 30 }}>
                    <HeadingTitle text={"Non HF problem list"} />
                    {hfProblemList?.length > 0 ? (
                      <>
                        <Grid container spacing={2}>
                          {hfProblemList.map((item, index) => {
                            return (
                              <>
                                <Grid item xs={9} key={item.id}>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                  >
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      size="small"
                                      placeholder="Enter Non Non HF problem Name"
                                      value={item?.value}
                                      onChange={(e) =>
                                        handleSingleChange(
                                          e,
                                          index,
                                          hfProblemList,
                                          setHfProblemList
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                {hfProblemList?.length > 1 && (
                                  <Grid item xs={3}>
                                    <Box>
                                      <Button
                                        variant="contained"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => handleHfRemove(item.id)}
                                      >
                                        Remove
                                      </Button>
                                    </Box>
                                  </Grid>
                                )}
                              </>
                            );
                          })}
                        </Grid>
                      </>
                    ) : (
                      <Typography variant="h6">None</Typography>
                    )}

                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Box sx={{ marginTop: "40px", marginLeft: "18px" }}>
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleHfList}
                          >
                            Add
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider style={{ marginTop: 30 }} />
                  <div style={{ marginTop: 30 }}>
                    <HeadingTitle text={"Non HF Medication list"} />
                    {hfMedicalList?.length > 0 ? (
                      <>
                        <Grid container spacing={2}>
                          {hfMedicalList.map((item,index) => {
                            return (
                              <>
                                <Grid item xs={9} key={item.id}>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                  >
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      size="small"
                                      placeholder="Enter Non HF Medication  Name"
                                      value={item?.value}
                                      onChange={(e) =>
                                        handleSingleChange(
                                          e,
index,
                                          hfMedicalList,
                                          setHfMedicalList
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                {hfMedicalList?.length > 1 && (
                                  <Grid item xs={3}>
                                    <Box>
                                      <Button
                                        variant="contained"
                                        startIcon={<DeleteIcon />}
                                        onClick={() =>
                                          handleHfMedicalRemove(item.id)
                                        }
                                      >
                                        Remove
                                      </Button>
                                    </Box>
                                  </Grid>
                                )}
                              </>
                            );
                          })}
                        </Grid>
                      </>
                    ) : (
                      <Typography variant="h6">None</Typography>
                    )}

                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Box sx={{ marginTop: "40px", marginLeft: "18px" }}>
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleHfMedicalList}
                          >
                            Add
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <div style={{ marginTop: 30 }}>
              <HeadingTitle text={"Blood Biomarker "} />
              <Grid container spacing={2}>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={patientData.bloodBiomarkerDate1}
                        inputFormat="DD/MM/YYYY"
                        onChange={(date) => {
                          handleChangePatient(
                            date,
                            "bloodBiomarkerDate1",
                            true
                          );
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField size="small" {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>HB (g/dl)</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter HB"
                      value={patientData?.bloodHB || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodHB")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Blood Sugar</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Blood Sugar"
                      value={patientData?.bloodSugar || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodSugar")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Na (mmo1/L)</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Na (mmo1/L)"
                      value={patientData?.bloodNammo1 || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodNammo1")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>K (mmo1/L)</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter K (mmo1/L)"
                      value={patientData?.bloodKmmo1 || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodKmmo1")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Mg (mmo1/L)</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Mg (mmo1/L)"
                      value={patientData?.bloodMgmmo1 || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodMgmmo1")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>S Creat (mg%)</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter S Creat (mg%)"
                      value={patientData?.bloodSCreat || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodSCreat")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>BUN (mg%)</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter BUN (mg%)"
                      value={patientData?.bloodBUN || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodBUN")
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 30 }} />
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Date</Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={patientData.bloodBiomarkerDate2}
                        inputFormat="DD/MM/YYYY"
                        onChange={(date) => {
                          handleChangePatient(
                            date,
                            "bloodBiomarkerDate2",
                            true
                          );
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField size="small" {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Trop- I</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Trop- I"
                      value={patientData?.bloodTrop || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodTrop")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>INR</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter INR"
                      value={patientData?.bloodINR || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodINR")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>APTT</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter APTT"
                      value={patientData?.bloodAPTT || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodAPTT")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>TSH</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter TSH"
                      value={patientData?.bloodTSH || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodTSH")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>HBA1c</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter HBA1c"
                      value={patientData?.bloodHBA1c || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodHBA1c")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>eGFR</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter eGFR"
                      value={patientData?.bloodeGFR || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodeGFR")
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 30 }} />
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Date</Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={patientData.bloodBiomarkerDate3}
                        inputFormat="DD/MM/YYYY"
                        onChange={(date) => {
                          handleChangePatient(
                            date,
                            "bloodBiomarkerDate3",
                            true
                          );
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField size="small" {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>CT Angiogram</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter CT Angiogram"
                      value={patientData?.bloodAngiogram || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodAngiogram")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>S Iron</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter S Iron"
                      value={patientData?.bloodIron || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodIron")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Ferritin</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Ferritin"
                      value={patientData?.bloodFerritin || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodFerritin")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>TIBC</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter TIBC"
                      value={patientData?.bloodTIBC || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodTIBC")
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Typography>Chest X-ray</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Chest X-ray"
                      value={patientData?.bloodeChestxray || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "bloodeChestxray")
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <div style={{ marginTop: 30 }}>
              <div className="title_radio_btn_area">
                <HeadingTitle text={"Lipid Profile "} />
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.lipidProfileStatus || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "lipidProfileStatus")
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      onChange={() => setLipidCondition(true)}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      onChange={() => setLipidCondition(false)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {lipidCondition && (
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Date</Typography>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={patientData.lipidDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(date) => {
                              handleChangePatient(date, "lipidDate", true);
                            }}
                            fullWidth
                            renderInput={(params) => (
                              <TextField size="small" {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>TC</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter TC"
                          value={patientData?.lipidtc || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "lipidtc")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>TG</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter TG"
                          value={patientData?.lipidtg || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "lipidtg")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>LDL</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter LDL"
                          value={patientData?.lipidldl || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "lipidldl")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>HDL</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter HDL"
                          value={patientData?.lipidhdl || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "lipidhdl")
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider style={{ marginTop: 30 }} />
                </>
              )}
            </div>
            <div style={{ marginTop: 30 }}>
              <div className="title_radio_btn_area">
                <HeadingTitle text={"Angiogram"} />
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.angiogramStatus || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "angiogramStatus")
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio checked={angiogramCondition} />}
                      label="Yes"
                      onChange={() => setAngiogramCondition(true)}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      onChange={() => setAngiogramCondition(false)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {angiogramCondition && (
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Date</Typography>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={patientData.angiogramDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(date) => {
                              handleChangePatient(date, "angiogramDate", true);
                            }}
                            fullWidth
                            renderInput={(params) => (
                              <TextField size="small" {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>LM</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter LM"
                          value={patientData?.angiogramlm || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "angiogramlm")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>TVD</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter TVD"
                          value={patientData?.angiogramtvd || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "angiogramtvd")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>DVD</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter DVD"
                          value={patientData?.angiogramdvd || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "angiogramdvd")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>SVD</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter SVD"
                          value={patientData?.angiogramsvd || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "angiogramsvd")
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider style={{ marginTop: 30 }} />
                </>
              )}
            </div>
            <div style={{ marginTop: 30 }}>
              <div className="title_radio_btn_area">
                <HeadingTitle text={"NT-Pro BNP"} />
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.ntroBnpStatus || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "ntroBnpStatus")
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      onChange={() => setNonBnCondition(true)}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      onChange={() => setNonBnCondition(false)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {nonBnpCondition && (
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Date</Typography>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={patientData.ntroBnpDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(date) => {
                              handleChangePatient(date, "ntroBnpDate", true);
                            }}
                            fullWidth
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Finding</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter Finding"
                          value={patientData?.ntroBnpFinding || ""}
                          onChange={(e) =>
                            handleChangePatient(
                              e.target.value,
                              "ntroBnpFinding"
                            )
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider style={{ marginTop: 30 }} />
                </>
              )}
            </div>
            <div style={{ marginTop: 30 }}>
              <div className="title_radio_btn_area">
                <HeadingTitle text={"ECG"} />
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.ecgStatus || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "ecgStatus")
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      onChange={() => setEcgCondition(true)}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      onChange={() => setEcgCondition(false)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {ecgCondition && (
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Date</Typography>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={patientData.ecgDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(date) => {
                              handleChangePatient(date, "ecgDate", true);
                            }}
                            renderInput={(params) => (
                              <TextField size="small" {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Finding</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter Finding"
                          value={patientData?.ecgFinding || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "ecgFinding")
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider style={{ marginTop: 30 }} />
                </>
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={7}>
            <div style={{ marginTop: 30 }}>
              <div className="title_radio_btn_area">
                <HeadingTitle text={"ECHO"} />
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.echoStatus || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "echoStatus")
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      onChange={() => setEchoCondition(true)}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      onChange={() => setEchoCondition(false)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {echoCondition && (
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Date</Typography>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={patientData.echoDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(date) => {
                              handleChangePatient(date, "echoDate", true);
                            }}
                            fullWidth
                            renderInput={(params) => (
                              <TextField size="small" {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>LA</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter LA"
                          value={patientData?.echoLa || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "echoLa")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>LVIDd</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter LVIDd"
                          value={patientData?.echoLavid || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "echoLavid")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>LVIDs</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter LVIDs"
                          value={patientData?.echoLavids || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "echoLavids")
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>LVEF</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter LVEF"
                          value={patientData?.echoLvef || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "echoLvef")
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider style={{ marginTop: 30 }} />
                </>
              )}
            </div>
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item md={6}>
                <FormGroup>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    MR
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={patientData?.mrRadioButton || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "mrRadioButton")
                      }
                    >
                      <FormControlLabel
                        value="gr1"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "mrRadioButton",
                                patientData?.mrRadioButton
                              )
                            }
                          />
                        }
                        label="Gr I"
                      />
                      <FormControlLabel
                        value="gr2"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "mrRadioButton",
                                patientData?.mrRadioButton
                              )
                            }
                          />
                        }
                        label="Gr II"
                      />
                      <FormControlLabel
                        value="gr3"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "mrRadioButton",
                                patientData?.mrRadioButton
                              )
                            }
                          />
                        }
                        label="Gr III"
                      />
                      <FormControlLabel
                        value="gr4"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "mrRadioButton",
                                patientData?.mrRadioButton
                              )
                            }
                          />
                        }
                        label="Gr IV"
                      />
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    AR
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={patientData?.arRadioButton || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "arRadioButton")
                      }
                    >
                      <FormControlLabel
                        value="gr1"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "arRadioButton",
                                patientData?.arRadioButton
                              )
                            }
                          />
                        }
                        label="Gr I"
                      />
                      <FormControlLabel
                        value="gr2"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "arRadioButton",
                                patientData?.arRadioButton
                              )
                            }
                          />
                        }
                        label="Gr II"
                      />
                      <FormControlLabel
                        value="gr3"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "arRadioButton",
                                patientData?.arRadioButton
                              )
                            }
                          />
                        }
                        label="Gr III"
                      />
                      <FormControlLabel
                        value="gr4"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "arRadioButton",
                                patientData?.arRadioButton
                              )
                            }
                          />
                        }
                        label="Gr IV"
                      />
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    TR
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={patientData?.trRadioButton || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "trRadioButton")
                      }
                    >
                      <FormControlLabel
                        value="gr1"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "trRadioButton",
                                patientData?.trRadioButton
                              )
                            }
                          />
                        }
                        label="Gr I"
                      />
                      <FormControlLabel
                        value="gr2"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "trRadioButton",
                                patientData?.trRadioButton
                              )
                            }
                          />
                        }
                        label="Gr II"
                      />
                      <FormControlLabel
                        value="gr3"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "trRadioButton",
                                patientData?.trRadioButton
                              )
                            }
                          />
                        }
                        label="Gr III"
                      />
                      <FormControlLabel
                        value="gr4"
                        control={
                          <Radio
                            onClick={(e) =>
                              handleCheckRadioPatient(
                                e.target.value,
                                "trRadioButton",
                                patientData?.trRadioButton
                              )
                            }
                          />
                        }
                        label="Gr IV"
                      />
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Wall motion abnormality
                  </Typography>

                  {motionCheckBoxList.map((item, index) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              handleCheckbox(
                                item?.id,
                                index,
                                motionCheckBoxList,
                                setMotionCheckBoxList
                              )
                            }
                            checked={motionCheckBoxList[index]?.isChecked}
                          />
                        }
                        label={item?.label}
                        key={index}
                      />
                    );
                  })}
                </FormGroup>
              </Grid>
              <Grid item md={6}>
                <Typography className="symptoms_title"></Typography>
                <FormGroup>
                  <div>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      Others
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Others Issue"
                      fullWidth
                      value={patientData?.motionOthers || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "motionOthers")
                      }
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      MS
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.msRadioButton || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "msRadioButton")
                        }
                      >
                        <FormControlLabel
                          value="mild"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "msRadioButton",
                                  patientData?.msRadioButton
                                )
                              }
                            />
                          }
                          label="Mild"
                        />
                        <FormControlLabel
                          value="moderate"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "msRadioButton",
                                  patientData?.msRadioButton
                                )
                              }
                            />
                          }
                          label="Moderate"
                        />
                        <FormControlLabel
                          value="severe"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "msRadioButton",
                                  patientData?.msRadioButton
                                )
                              }
                            />
                          }
                          label="Severe"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      PASP
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Others Issue"
                      fullWidth
                      value={patientData?.paspInput || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "paspInput")
                      }
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      Daistolic dysfunction
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={patientData?.daistolicStatus || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "daistolicStatus")
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "daistolicStatus",
                                  patientData?.daistolicStatus
                                )
                              }
                            />
                          }
                          label="I"
                        />
                        <FormControlLabel
                          value="2"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "daistolicStatus",
                                  patientData?.daistolicStatus
                                )
                              }
                            />
                          }
                          label="II"
                        />
                        <FormControlLabel
                          value="3"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "daistolicStatus",
                                  patientData?.daistolicStatus
                                )
                              }
                            />
                          }
                          label="III"
                        />
                        <FormControlLabel
                          value="4"
                          control={
                            <Radio
                              onClick={(e) =>
                                handleCheckRadioPatient(
                                  e.target.value,
                                  "daistolicStatus",
                                  patientData?.daistolicStatus
                                )
                              }
                            />
                          }
                          label="IIII"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      Tissue doppler findings (GLS)
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Others Issue"
                      fullWidth
                      value={patientData?.tissueInput || ""}
                      onChange={(e) =>
                        handleChangePatient(e.target.value, "tissueInput")
                      }
                    />
                  </div>
                </FormGroup>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 30 }} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <div style={{ marginTop: 30 }}>
              <div className="title_radio_btn_area">
                <HeadingTitle text={"6-minutes walk test"} />
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.walkminutesStatus || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "walkminutesStatus")
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      onChange={() => setMinCondition(true)}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      onChange={() => setMinCondition(false)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {minCondition && (
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={patientData.walkDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(date) => {
                              handleChangePatient(date, "walkDate", true);
                            }}
                            fullWidth
                            renderInput={(params) => (
                              <TextField size="small" {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Finding</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter Finding"
                          value={patientData?.walkFinding || ""}
                          onChange={(e) =>
                            handleChangePatient(e.target.value, "walkFinding")
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider style={{ marginTop: 30 }} />
                </>
              )}
            </div>
            <div style={{ marginTop: 30 }}>
              <div className="title_radio_btn_area">
                <HeadingTitle text={"Hotel/ Event Recorder"} />
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio checked={eventCondition} />}
                      label="Yes"
                      onChange={() => setEventCondition(true)}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      onChange={() => setEventCondition(false)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {eventCondition && (
                <>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12} sx={{ marginBottom: 2 }}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            size="small"
                          >
                            <Typography>Date</Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={patientData.hotelDate}
                                inputFormat="DD/MM/YYYY"
                                onChange={(date) => {
                                  handleChangePatient(date, "hotelDate", true);
                                }}
                                fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    fullWidth
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={patientData?.hotelpvc}
                              onChange={(e) => {
                                handleChangePatient(
                                  e.target.checked,
                                  "hotelpvc"
                                );
                              }}
                            />
                          }
                          label="PVC"
                        />

                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Ventricular Arrhythmia"
                          />
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={patientData?.hotelVentricularStatus || ""}
                            onChange={(e) =>
                              handleChangePatient(
                                e.target.value,
                                "hotelVentricularStatus"
                              )
                            }
                          >
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                              checked={ventricularCondition === false}
                              onChange={() => {
                                setVentricularCondition(false);
                              }}
                            />
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                              onChange={() => {
                                setVentricularCondition(true);
                              }}
                            />
                          </RadioGroup>
                        </div>
                        {ventricularCondition && (
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{ marginLeft: 3 }}
                            value={patientData?.hotelVentricularValue || ""}
                            onChange={(e) =>
                              handleChangePatient(
                                e.target.value,
                                "hotelVentricularValue"
                              )
                            }
                          >
                            <FormControlLabel
                              value="complex_pvc"
                              control={
                                <Radio
                                  onClick={(e) =>
                                    handleCheckRadioPatient(
                                      e.target.value,
                                      "hotelVentricularValue",
                                      patientData?.hotelVentricularValue
                                    )
                                  }
                                />
                              }
                              label="Complex PVC"
                            />
                            <FormControlLabel
                              value="nsvt"
                              control={
                                <Radio
                                  onClick={(e) =>
                                    handleCheckRadioPatient(
                                      e.target.value,
                                      "hotelVentricularValue",
                                      patientData?.hotelVentricularValue
                                    )
                                  }
                                />
                              }
                              label="NSVT"
                            />
                            <FormControlLabel
                              value="vt"
                              control={
                                <Radio
                                  onClick={(e) =>
                                    handleCheckRadioPatient(
                                      e.target.value,
                                      "hotelVentricularValue",
                                      patientData?.hotelVentricularValue
                                    )
                                  }
                                />
                              }
                              label="VT"
                            />
                          </RadioGroup>
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormGroup>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Atrial Arrhythmia"
                          />
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={patientData?.atrialStatus || ""}
                            onChange={(e) =>
                              handleChangePatient(
                                e.target.value,
                                "atrialStatus"
                              )
                            }
                          >
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                              onChange={() => {
                                setAtrialCondition(false);
                              }}
                            />
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                              onChange={() => {
                                setAtrialCondition(true);
                              }}
                            />
                          </RadioGroup>
                        </div>
                        {atrialCondition && (
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{ marginLeft: 3 }}
                            value={patientData?.atrialValue || ""}
                            onChange={(e) =>
                              handleChangePatient(e.target.value, "atrialValue")
                            }
                          >
                            <FormControlLabel
                              value="acp"
                              control={
                                <Radio
                                  onClick={(e) =>
                                    handleCheckRadioPatient(
                                      e.target.value,
                                      "atrialValue",
                                      patientData?.atrialValue
                                    )
                                  }
                                />
                              }
                              label="ACP"
                            />
                            <FormControlLabel
                              value="af"
                              control={
                                <Radio
                                  onClick={(e) =>
                                    handleCheckRadioPatient(
                                      e.target.value,
                                      "atrialValue",
                                      patientData?.atrialValue
                                    )
                                  }
                                />
                              }
                              label="AF"
                            />
                          </RadioGroup>
                        )}

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={patientData?.heartRate}
                              onChange={(e) => {
                                handleChangePatient(
                                  e.target.checked,
                                  "heartRate"
                                );
                              }}
                            />
                          }
                          label="Heart rate variability"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Divider style={{ marginTop: 30 }} />
                </>
              )}
            </div>
            <div style={{ marginTop: 30 }}>
              <div className="title_radio_btn_area">
                <HeadingTitle text={"Others"} />
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={patientData?.walkOtherStatus || ""}
                    onChange={(e) =>
                      handleChangePatient(e.target.value, "walkOtherStatus")
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      onChange={() => setOthersCondition(true)}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      onChange={() => setOthersCondition(false)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {othersCondition && (
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Date</Typography>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={patientData.walkOtherDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(date) => {
                              handleChangePatient(date, "walkOtherDate", true);
                            }}
                            fullWidth
                            renderInput={(params) => (
                              <TextField size="small" {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Typography>Finding</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="Enter Finding"
                          value={patientData?.walkOthersFinding || ""}
                          onChange={(e) =>
                            handleChangePatient(
                              e.target.value,
                              "walkOthersFinding"
                            )
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider style={{ marginTop: 30 }} />
                </>
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={9}>
            <Stack>
              <FormControl fullWidth variant="outlined" size="small">
                <Typography>Diagnosis</Typography>

                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Enter Diagnosis"
                  multiline={true}
                  rows={5}
                  maxRows={10}
                  value={patientData?.diagnosisComment || ""}
                  onChange={(e) =>
                    handleChangePatient(e.target.value, "diagnosisComment")
                  }
                />
              </FormControl>
            </Stack>
            <div>
              <Divider style={{ marginTop: 30 }} />
              <div style={{ marginTop: 30 }}>
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={6} xs={12}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <Typography>Created by</Typography>

                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Created by Name"
                        value={patientData?.createdBy || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "createdBy")
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <Typography>Assessed by</Typography>

                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Assessed by Name"
                        value={patientData?.assessedBy || ""}
                        onChange={(e) =>
                          handleChangePatient(e.target.value, "assessedBy")
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <Typography>Date of Assessment</Typography>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={patientData.assessmentDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(date) => {
                            handleChangePatient(date, "assessmentDate", true);
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField size="small" {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Divider style={{ marginTop: 30 }} />
            <Stack sx={{ marginTop: "30px" }}>
              <FormControl fullWidth variant="outlined" size="small">
                <Typography>Doctor Review</Typography>
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <Rating
                    name="hover-feedback"
                    value={rattingValue}
                    precision={0.5}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                      setRattingValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {value !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labels[hover !== -1 ? hover : value]}
                    </Box>
                  )}
                </Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Enter Your Message"
                  multiline={true}
                  rows={5}
                  maxRows={10}
                  value={patientData?.doctorReviewComment || ""}
                  onChange={(e) =>
                    handleChangePatient(e.target.value, "doctorReviewComment")
                  }
                />
              </FormControl>
            </Stack>
            <Box sx={{ marginTop: "40px", textAlign: "right" }}>
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Box>
          </TabPanel>
        </Box>
      </form>
      <div className="tab_prev_next_button_area d-flex-between">
        <Button
          variant="contained"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={handlePrevTab}
          disabled={value === 0}
        >
          Prev
        </Button>

        <Button
          variant="contained"
          endIcon={<ChevronRightIcon />}
          onClick={handleNextTab}
          disabled={value === tabHeader.length - 1}
        >
          Next
        </Button>
      </div>
    </section>
     </>
  );
}

export default AddPatientPage;
