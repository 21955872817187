import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, Button, Divider, Grid, Paper } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import ChangePassword from "./ChangePassword";
import { Helmet } from "react-helmet-async";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const validationSchema = Yup.object().shape({
  name: Yup.string(),
  uploadLogo: Yup.string(),
  number: Yup.string(),
  email: Yup.string().email(),
  web: Yup.string().url(),
  adress: Yup.string(),
});
function AccountSetting() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Get Data
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      name: "Johan",
      number: "+881651606",
      email: "example@gmail.com",
      web: "www.example.com",
      adress: "Dhaka,Bangladesh",
    },
  });

  const handleAddStudent = (data) => {
    console.log("change profile", data);
    toast.success("Successfully update settings");
    reset();
  };

  return (
    <>
      {" "}
      <Helmet>
        <title>Account Settings</title>
        <meta name="description" content="Web description" />
        <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{
            maxWidth: "700px",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Update Profile" {...a11yProps(0)} />
              <Tab label=" Change Password" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <form action="" onSubmit={handleSubmit(handleAddStudent)}>
              <Avatar
                variant="square"
                sx={{
                  width: "100px",
                  height: "100px",
                  margin: "auto",
                  objectFit: "cover",
                }}
              >
                <img
                  src="/images/user_img.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </Avatar>
              <Box sx={{ width: "150px", margin: "10px auto 0 auto" }}>
                {" "}
                <Button type="button" variant="contained">
                  {" "}
                  <label
                    htmlFor="changeUserImage"
                    className="label_btn"
                    style={{ display: "flex" }}
                  >
                    Change Image{" "}
                  </label>
                </Button>
                <input
                  type="file"
                  id="changeUserImage"
                  style={{ display: "none" }}
                />{" "}
              </Box>

              <Divider sx={{ margin: "20px 0" }} />
              <Controller
                render={({ field, formState }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ mt: 2 }}
                  >
                    <Typography
                      color={!!formState.errors?.name ? red[700] : ""}
                    >
                      Name
                    </Typography>
                    <OutlinedInput
                      {...field}
                      error={!!formState.errors?.name}
                      placeholder="Enter Name"
                    />
                    {!!formState.errors?.name ? (
                      <FormHelperText error>
                        {errors?.name?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                )}
                name="name"
                control={control}

              />
              <Controller
                render={({ field, formState }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ mt: 2 }}
                  >
                    <Typography
                      color={!!formState.errors?.email ? red[700] : ""}
                    >
                      Email
                    </Typography>
                    <OutlinedInput
                      {...field}
                      error={!!formState.errors?.email}
                      placeholder="Enter Email"
                    />
                    {!!formState.errors?.email ? (
                      <FormHelperText error>
                        {errors?.email?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                )}
                name="email"
                control={control}

              />
              <Controller
                render={({ field, formState }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ mt: 2 }}
                  >
                    <Typography
                      color={!!formState.errors?.number ? red[700] : ""}
                    >
                      Number
                    </Typography>
                    <OutlinedInput
                      {...field}
                      error={!!formState.errors?.number}
                      placeholder="Enter Number"
                    />
                    {!!formState.errors?.number ? (
                      <FormHelperText error>
                        {errors?.number?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                )}
                name="number"
                control={control}

              />
              <Controller
                render={({ field, formState }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ mt: 2 }}
                  >
                    <Typography
                      color={!!formState.errors?.adress ? red[700] : ""}
                    >
                      Address
                    </Typography>
                    <OutlinedInput
                      {...field}
                      error={!!formState.errors?.adress}
                      placeholder="Enter Address"
                    />
                    {!!formState.errors?.adress ? (
                      <FormHelperText error>
                        {errors?.adress?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                )}
                name="adress"
                control={control}

              />
              <Grid container spacing={1} sx={{ marginTop: "40px" }}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: "100%" }}
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%" }}
                    color="secondary"
                    onClick={() => window.history.go(-1)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePassword />
          </TabPanel>
        </Paper>
      </Box>
    </>
  );
}

export default AccountSetting;
