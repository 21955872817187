import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useState, useEffect } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { MdAlternateEmail } from "react-icons/md";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function ForgetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [forgetOtp, setForgetOtp] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const loginBg = "/images/login_bg.jpg";
  //react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log("data", data);

    if (forgetOtp === true) {
      console.log("true");
      navigate("/change-password");
    } else {
      console.log("false");

      toast.success("Check Your Email");
      setForgetOtp(true);
    }
  };
  return (
    <div className="login_wrapper">
      <Box>
        <Grid container spacing={2}>
          <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
            <Paper elevation={4} sx={{ bgcolor: blue[200] }}>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <img
                  sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={loginBg}
                  alt=""
                />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ height: "98vh" }}
              >
                <Box sx={{ maxWidth: 450 }}>
                  <Avatar sx={{ bgcolor: blue[500], p: 1, mx: "auto" }}>
                    HF
                  </Avatar>
                  <Typography sx={{ my: 2 }} variant="h6" textAlign="center">
                    Forget Password
                  </Typography>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    {forgetOtp ? (
                      <Controller
                        render={({ field, formState }) => (
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="otp-number">Code</InputLabel>
                            <OutlinedInput
                              id="otp-number"
                              {...field}
                              error={!!formState.errors?.otp}
                              type="text"
                              label="Code"
                              autoComplete=""
                              required
                            />
                            {!!formState.errors?.otp ? (
                              <FormHelperText error>
                                {errors?.otp?.message}
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        )}
                        name="otp"
                        control={control}
                        defaultValue=""
                      />
                    ) : (
                      <Controller
                        render={({ field, formState }) => (
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="login-email">Email</InputLabel>
                            <OutlinedInput
                              id="login-email"
                              {...field}
                              error={!!formState.errors?.email}
                              label="Email"
                            />
                            {!!formState.errors?.email ? (
                              <FormHelperText error>
                                {errors?.email?.message}
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        )}
                        name="email"
                        control={control}
                        defaultValue=""
                      />
                    )}

                    <Button
                      sx={{ mt: 3 }}
                      size="large"
                      fullWidth
                      variant="contained"
                      type="submit"
                    >
                      {forgetOtp ? "Check" : "Send Email"}
                    </Button>
                  </form>
                  {forgetOtp && (
                    <Button
                      variant="contained"
                      onClick={() => setForgetOtp(false)}
                      sx={{ mt: 3 }}
                    >
                      {" "}
                      Change Email{" "}
                    </Button>
                  )}
                </Box>
              </Stack>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default ForgetPassword;
