import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import SidebarContext from "../Context/SidebarContext";
import Header from "./Header";
import Sidebar from "./sidebar/Sidebar";

function Layout() {
  //Sidebar Handle Events
  const { toggleSidebar } = useContext(SidebarContext);
  return (
    <div
      className={`main_content_top_wrapper ${
        toggleSidebar ? "sidebar_toggle_active" : " "
      }`}
    >
      <Header />
      <Sidebar />
      <main className="main_content_area">
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
