import { Avatar, Box, Button, Divider, Paper } from "@mui/material";
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { AiOutlineUser } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { TbPhoneCall } from "react-icons/tb";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
function Profile() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Profile</title>
        <meta name="description" content="Web description" />
        <link rel="link-test" href="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <Box className="profile_wrapper">
        <Paper
          sx={{
            maxWidth: "500px",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "20px",
          }}
        >
          <Avatar
            variant="square"
            sx={{
              width: "100px",
              height: "100px",
              margin: "auto",
              objectFit: "cover",
            }}
          >
            <img
              src="/images/user_img.jpg"
              alt=""
              style={{ objectFit: "cover" }}
            />
          </Avatar>
          <Divider sx={{ margin: "20px 0" }} />

          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#00C49F" }}>
                  <AiOutlineUser color="white" size={18} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Name" secondary="Johan" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#00C49F" }}>
                  <MdEmail color="white" size={18} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Email" secondary="Example@gmail.com" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#00C49F" }}>
                  <TbPhoneCall color="white" size={18} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Phone" secondary="+8801654981851" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#00C49F" }}>
                  <HiOutlineLocationMarker color="white" size={18} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Address" secondary="Dhaka, Bangladesh" />
            </ListItem>
          </List>
          <Button
            variant="contained"
            sx={{ width: "100%", marginTop: "20px" }}
            onClick={() => navigate("/my-account")}
          >
            Update Profile
          </Button>
        </Paper>
      </Box>
    </>
  );
}

export default Profile;
